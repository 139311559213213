import React, { useState, useRef } from "react";

import EditAvatarModal from "./EditAvatarModal";

import { uploadImage } from "../../../utils/uploadImages";

const EditAvatarButton = (props) => {
  const { image, setProfileData, profileData, editAvatarOrBackground } = props;
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [imageSource, setImageSource] = useState();

  const fileRef = useRef(null);

  async function handleImageUpload(event) {
    event.preventDefault();
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);

      setOpenEditorModal(true);
    });
  }

  function handleOpenFilePicker() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  return (
    <>
      <div className="">
        <input
          ref={fileRef}
          onChange={handleImageUpload}
          className="hidden"
          accept="image/x-png,image/jpeg"
          type="file"
        />
        <img
          onClick={handleOpenFilePicker}
          className="h-20 mt-2 rounded-md p-[2px] mx-auto border border-stone-200 bg-white cursor-pointer"
          src={image}
          alt="avatar"
        />
      </div>
      {openEditorModal && (
        <EditAvatarModal
          imageSource={imageSource}
          profileData={profileData}
          setProfileData={setProfileData}
          setShowModal={setOpenEditorModal}
          editAvatarOrBackground={editAvatarOrBackground}
        />
      )}
    </>
  );
};
export default EditAvatarButton;
