export function countClicks(statistics) {
  const socialMediaCount = statistics.reduce(
    (sum, obj) => sum + obj.counter,
    0
  );
  return socialMediaCount;
}

export function sortStatsByCounter(statistics) {
  return Object.values(statistics).sort((a, b) => b.counter - a.counter);
}

export function highestFiveClicks(statistics) {
  const top5 = Object.values(statistics)
    .sort((a, b) => b.counter - a.counter)
    .slice(0, 5);
  return top5;
}

// Usados en la interfaz administrativa
export function highestFiveClicksArray(statistics) {
  const top5 = statistics.sort((a, b) => b.counter - a.counter).slice(0, 5);
  return top5;
}

export function highestNByAttribute(array, attribute, n) {
  const topN = array.sort((a, b) => b[attribute] - a[attribute]).slice(0, n);
  return topN;
}

export function mergeViewsByArea(array) {
  const uniqueAreas = new Set();
  const mergedAreas = {};
  for (let obj of array) {
    let key = obj.rubro;

    if (key === "") {
      key = "No definido";
    }

    if (uniqueAreas.has(key)) {
      mergedAreas[key].counter += obj.counter;
      continue;
    }

    uniqueAreas.add(key);
    mergedAreas[key] = { rubro: key, counter: obj.counter };
  }
  return Object.values(mergedAreas);
}

export function mergeStats(userStats) {
  // Usado para reutilizar partes del dashboard de usuario normal.
  // Combina las vistas y contadores de enlaces (según url y title)
  let mergedStats = {
    username: "",
    public_name: "",
    rubro: "",
    total: userStats.length,
    statistics: {
      profile: 0,
      custom_social_list: [],
    },
  };
  const views = userStats.map((userStat) => userStat.counter);
  const totalViews = views.reduce((old, next) => {
    return old + next;
  }, 0);

  mergedStats.statistics.counter = totalViews;

  const nestedLinks = userStats.map(
    (userStat) => userStat.statistics.custom_social_list
  );
  const flatLinks = nestedLinks.flat();

  const uniqueLinks = new Set();
  const mergedLinks = {};
  for (let link of flatLinks) {
    let key = JSON.stringify([link.title, link.url]);

    if (uniqueLinks.has(key)) {
      mergedLinks[key].counter += link.counter;
      continue;
    }

    uniqueLinks.add(key);
    mergedLinks[key] = { ...link };
  }
  mergedStats.statistics.custom_social_list = Object.values(mergedLinks);

  console.log(mergedStats)
  return mergedStats;
}

export function mostClickedLinksAdmin(teamStats, n) {
  let topLinks = [];
  let allLinks = [];

  for (const user of teamStats) {
    for (const link of user.statistics.custom_social_list) {
      const linkObj = {
        title: link.title,
        counter: link.counter,
        image: link.image,
        user: user,
      };
      allLinks.push(linkObj);
    }
  }

  topLinks = allLinks.sort((a, b) => b.counter - a.counter).slice(0, n)
  return topLinks
}

export function getAllCompanyAreas(users) {
  const areas = new Set();
  for (let user of users) {
    areas.add(user.rubro);
  }
  return areas;
}
