  import axios from 'axios'
  import { DOMAIN } from "../../../config/Constant";






  // Crear un nuevo descuento
  export const createDiscount = async (data, token) => {
    try {
      const response = await axios.post(`${DOMAIN}pay/discounts/discount/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
      });

      return response.data; 
    } catch (error) {
      throw error; 
    }
  };


  // Función para obtener información del usuario por ID de descuento
  export const getUserByDiscount = (token, discountId) => {
    return axios.get(`${DOMAIN}pay/discounts/user/${discountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  };

   // Función para obtener información del descuento por código de verificación de descuento
   export const getDiscountByVerificationCode = (token, verification_code) => {
    return axios.get(`${DOMAIN}pay/discount/verification_code/${verification_code}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  };

  
  export const getUserDiscounts = (token) => {
    return axios.get(`${DOMAIN}pay/discounts/user/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  };



  export const getAllDiscounts =(token)=>{
    return axios.get(`${DOMAIN}pay/discounts/discount/`,{
      headers:{
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
    
  };


  // Obtener un descuento por ID
  export const getDiscountById = (token, discountId) => {
    return axios.get(`${DOMAIN}pay/discounts/discount/${discountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  };

  // Actualizar un descuento por ID
  export const updateDiscount = (token, discountId, updatedData) => {
    return axios.put(`${DOMAIN}pay/discounts/discount/${discountId}/`, updatedData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
  };




  export const getAllUsers=(token)=>{
    return axios.get(`${DOMAIN}admin/authentication/user/`,{
      headers:{
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      } 
    })
  }




  export const getAllSavedDiscounts =(token)=>{
    return axios.get(`${DOMAIN}pay/saved-discounts/saved-discount/`,{
      headers:{
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
    
  };

