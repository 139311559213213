import { OrderSummaryCollapsible } from "./OrderSummaryCollapsible";


export function OrderSummary(props) {
  const { products, deliveryCost, discountRate } = props;

  const subTotal = computeSubtotalProducts(products);
  const delivery = deliveryCost ?? 0;
  const total = subTotal + delivery;
  const descuento=discountRate ? 1 * discountRate
   : 0;
  const totalApagar = total - descuento;
  return (
    <div className="px-6 py-3 bg-stone-100">
      <div className="mt-3 mb-3">
        <OrderSummaryCollapsible>
          <>
            <div className="gap-1 mt-2 flex flex-col text-stone-800">
              {products.map((product, index) => (
                <div key={index} className="text-sm flex justify-between">
                  <p>
                    {product.title} &nbsp;
                    <strong className="font-medium">
                      ({product.quantity})
                    </strong>
                  </p>
                  <p>Bs {(product.price * product.quantity).toFixed(2) }</p>
                </div>
              ))}
            </div>
          </>
        </OrderSummaryCollapsible>
      </div>

      <div className="gap-y-2 w-full text-sm sm:text-base  grid grid-cols-2">
        <h3 className="text-stone-800">Subtotal</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">
          Bs {subTotal.toFixed(2)}
        </p>
        <h3 className="text-stone-800">Envío</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">
          Bs {delivery.toFixed(2)}
        </p>
        <h3 className="font-bold text-stone-800">Total</h3>
        <p className="text-right font-semibold whitespace-nowrap">
          Bs {total.toFixed(2)}
        </p>
        <h3 className="text-green-600 " >Descuento </h3>
        <p className="text-right text-green-600 whitespace-nowrap" id="discount_rate">
          Bs {descuento.toFixed(2)}
        </p>        
        <h3 className="font-bold text-stone-800">Total a Pagar</h3>
        <p className="text-right font-semibold whitespace-nowrap">
          Bs {totalApagar.toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export function computeSubtotalProducts(products) {
  return products.reduce((sum, product) => {
    return sum + product.price * product.quantity;
  }, 0);
}
