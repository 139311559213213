import { useState } from "react";

import "./PaymentPageStyles.css";

export function useThrowConfetti(props) {
  const DURATION = 1500;
  const PARTICLES = 20;

  const [display, setDisplay] = useState("block");

  function trigger() {
    setDisplay("block");
    setTimeout(() => {
      setDisplay("none");
    }, DURATION);
  }

  function randomConfettiProperties() {
    let x = (Math.random() - 0.5) * 200;
    let y = 30 + Math.random() * 20;
    let colors = ["#a864fd", "#29cdff", "#78ff44", "#ff214d", "#fdff3a"]
    let color = colors[getRandomInt(0, colors.length)]
    let duration = DURATION + Math.random() * 1000;

    return {
      displacement: x,
      height: y,
      color,
      duration,
    };
  }

  const container = (
    <div
      style={{
        display: display,
      }}
    >
      {[...Array(PARTICLES)].map((i, index) => (
        <Confetti key={index} {...randomConfettiProperties()} />
      ))}
    </div>
  );

  return { trigger, container };
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

function Confetti(props) {
  const { height, displacement, color, duration } = props;
  return (
    <div
      style={{
        backgroundColor: color,
        "--confetti-duration": `${duration}ms`,
        "--confetti-y": `${-height}px`,
        "--confetti-x": `${displacement}px`,
      }}
      className="confetti-animation z-50 w-2 h-2 absolute rounded-full"
    >
    </div>
  );
}
