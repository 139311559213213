import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB4Nr9YccIO3GRXPss7-XwTyccHkS2P0nw",
  authDomain: "tu-mascota-405715.firebaseapp.com",
  projectId: "tu-mascota-405715",
  storageBucket: "tu-mascota-405715.appspot.com",
  messagingSenderId: "558693469633",
  appId: "1:558693469633:web:1f3e526658ff684c50f340",
  measurementId: "G-MMFQE0CSCN"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);