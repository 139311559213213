import { MdVerified } from "react-icons/md";

export function ProductsCard(props) {
  const { image, hoverImage, hoverText, title, description, buttonText, link } =
    props;

  const { durationTag } = props;

  const { onClickImageOrButton } = props;

  function handleClickImageOrButton(event) {
    if (onClickImageOrButton) {
      onClickImageOrButton();
      return;
    }
  }
  return (
    <div className="relative h-full flex flex-col items-center">
      <button
        onClick={handleClickImageOrButton}
        className="group relative cursor-pointer"
      >
        <img
          src={image}
          aria-label={`comprar producto ${title}`}
          alt={title}
          className="h-36 rounded-md bg-cover group-hover:hidden"
        />
        <img
          src={hoverImage ?? image}
          alt={title}
          className="h-36 bg-cover hidden group-hover:block"
        />

        <div className="px-2 py-1 opacity-0 right-2 bottom-2 font-medium text-sm absolute transition-all group-hover:block group-active:scale-110 group-hover:opacity-100 bg-stone-950 text-stone-50 rounded-md">
          {hoverText}
        </div>
      </button>
      <h3 className="mt-4 font-semibold"> {title} </h3>
      <p className="text-center"> {description} </p>

      <div className="h-full"></div>
      <a
        onClick={handleClickImageOrButton}
        href={link}
        className="mt-4 px-4 py-1 rounded-lg bg-stone-950 text-stone-50 cursor-pointer"
      >
        {buttonText}
      </a>
      {durationTag ? (
        <div className="left-0 top-0 absolute">
          <div className="px-1 py-0.5 rounded-md text-sm flex items-center bg-amber-400">
            <MdVerified />
            <span className="ml-1"> 2 años </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
