import { useState } from "react";

import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { auth } from "../../../firebase/FirebaseUtils";
import { useForm } from "react-hook-form";
import { MdPostAdd } from "react-icons/md";
import { BsShieldFillCheck } from "react-icons/bs";
import LoadingButton from "../../../components/LoadingButton";

import "./adminComponentStyles.css";
import { sendExtendLicenseEmail } from "../Api/AdminServices";
import { useSelector } from "react-redux";

export function ExtendLicenseModal(props) {
  const token = useSelector((state) => state.login.token);

  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const { license } = props;
  function handleExtendLicense(data) {
    setIsLoading(true);

    data = {
      ...data, ...license,
    }
    sendExtendLicenseEmail(data, token)
      .then((response) => {
        setFormSent(true);
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <DialogPortal>
      <DialogOverlay className="fixed inset-0 bg-stone-950/25" />
      <DialogContent className="w-[90vw] max-w-lg px-7 pt-4 pb-4 z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-stone-300 shadow-lg fixed rounded-md bg-white">
        <div className="pt-2">
          <div className="flex items-center">
            <MdPostAdd size={24} />
            <p className="ml-2 font-medium text-base">Extensión de licencia</p>
          </div>
          <DialogDescription className="tracking-[0.02em] mt-4 text-sm text-stone-500">
            Si deseas renovar tu licencia envía esta solicitud y nos pondremos
            en contacto al correo electrónico dado.
          </DialogDescription>

          <form
            onSubmit={handleSubmit(handleExtendLicense)}
            className="mt-4 text-sm flex flex-col"
          >
            <label className="text-stone-800 font-medium" htmlFor="email">
              Correo electrónico
            </label>
            <input
              className="mt-1 px-4 py-2 border outline-none w-full"
              type="email"
              {...register("email", { value: auth.currentUser.email })}
              id="email"
              placeholder="Correo electrónico"
              required
            />
            {formSent ? (
              <div className="mt-2 flex items-center mx-auto text-emerald-600">
                <BsShieldFillCheck />
                <p className="tracking-[0.02em] ml-1">
                  Envíado exitosamente, nos pondrémos en contacto pronto
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="mt-4 flex items-center justify-end">
              <div className="flex sm:flex-row gap-2">
                <DialogClose>
                  <button
                    type="button"
                    className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
                  >
                    Cerrar
                  </button>
                </DialogClose>

                <LoadingButton
                  type="submit"
                  isLoading={isLoading}
                  className="px-8 py-1 font-medium w-fit rounded-md text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                >
                  Envíar
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </DialogPortal>
  );
}
