import { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";

import "./PaymentPageStyles.css";
import { useThrowConfetti } from "./ThrowConfetti";

export function FloatingShoppingCart(props) {
  const { quantity } = props;

  const [quantityArray, setQuantityArray] = useState([0, quantity]);
  useEffect(() => {
    quantityArray.push(quantity);
    const newArray = quantityArray.splice(1);
    if (newArray[1] > newArray[0]) {
      trigger();
    }
    setQuantityArray(newArray);
  }, [quantity]);
  const { container, trigger } = useThrowConfetti();

  return (
    <div className="fixed z-[5] isolate bottom-8 right-8 sm:right-16 sm:bottom-8">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full -z-10">
        {container}
      </div>

      <button
        onClick={trigger}
        className={`p-4 shadow-md rounded-full bg-stone-950`}
      >
        <AiOutlineShoppingCart className="text-2xl text-stone-100" />
      </button>
    </div>
  );
}
