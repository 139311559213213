import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Switch, SwitchThumb } from "@radix-ui/react-switch";
import { useEffect, useState } from "react";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { FaSort } from "react-icons/fa";
import { useSelector } from "react-redux";
import { IMAGE_DOMAIN } from "../../../config/Constant";
import { getUserInitials } from "../../../utils/getUserInitials";
import { blockEditionUser } from "../Api/AdminServices";

export function TableRow(props) {
  const { user, checked, isLoggedUser, setEmployeeSelected, onClickCheckBox } =
    props;

  const [isEditable, setIsEditable] = useState(user.is_editable);

  const token = useSelector((state) => state.login.token);

  function handleOnEdit() {
    setEmployeeSelected(user);
  }

  function handleCheckButton() {
    //setSelected(!selected);
    onClickCheckBox(!checked);
  }

  function handleEditIsEditable(value) {
    setIsEditable(value);
    blockEditionUser({ canEdit: value, id: user.id }, token).catch((error) => {
      console.log(error);
      setIsEditable(!value);
      window.alert(error);
    });
  }

  return (
    <tr
      className={`${
        checked ? "bg-stone-100" : ""
      } border-b border-stone-200 text-stone-900 hover:bg-stone-100`}
    >
      <td>
        <div className="flex justify-center">
          {checked ? (
            <BiCheckboxChecked
              onClick={handleCheckButton}
              className="p-1 box-content cursor-pointer"
              size={24}
            />
          ) : (
            <BiCheckbox
              onClick={handleCheckButton}
              className="p-1 box-content cursor-pointer"
              size={24}
            />
          )}
        </div>
      </td>
      <td
        onClick={handleCheckButton}
        className="pl-2 pr-6 py-4 flex items-center whitespace-nowrap"
      >
        <Avatar className="mr-4 h-10 w-10 pointer-events-none select-none text-sm inline-flex justify-center items-center text-center rounded-full bg-stone-200">
          <AvatarImage
            className="h-10 w-10 object-cover bg-contain rounded-full"
            src={`${IMAGE_DOMAIN}${user.profile.image}`}
          />
          <AvatarFallback>
            {getUserInitials(user.public_name, user.username)}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <a
            href={`https://soy.tumascota.digital/u/${user.username}`}
            target={"_blank"}
            rel="noreferrer"
            onClick={(event) => event.stopPropagation()}
            className=" inline-flex font-medium hover:underline"
          >
            {`${user.username} ${isLoggedUser ? "(Yo)" : ""}`}
          </a>
          <div className="text-xs text-stone-600">{user.email}</div>
        </div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap" onClick={handleCheckButton}>
        {user.rubro ?? "No definido"}
      </td>
      <td className="px-6 py-4 w-1/6 text-center">
        <Switch
          checked={isEditable}
          disabled={isLoggedUser}
          onCheckedChange={handleEditIsEditable}
          className="w-8 h-5 rounded-full transition-colors bg-red-600 data-[state='checked']:bg-green-600 data-[disabled]:bg-gray-500"
        >
          <SwitchThumb className="h-4 w-4 rounded-full block transition-transform ease-linear translate-x-[2px] data-[state='checked']:translate-x-[14px] bg-stone-100" />
        </Switch>
      </td>
      <td className="px-6 py-4 text-right">
        <button
          onClick={handleOnEdit}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Editar
        </button>
      </td>
    </tr>
  );
}

export function TableHeader(props) {
  const {
    setEmployees,
    employees,
    filteredEmployees = [],
    multipleSelected,
  } = props;
  const { setMultipleSelected } = props;

  const [areaReverse, setAreaReverse] = useState(false);
  const [userReverse, setUserReverse] = useState(false);

  function handleSortByUsername(props) {
    setAreaReverse(!areaReverse);

    const sorted = employees.sort((a, b) =>
      a.username.toLowerCase().localeCompare(b.username.toLowerCase())
    );

    if (areaReverse) {
      setEmployees([...sorted].reverse());
      return;
    }

    setEmployees([...sorted]);
  }

  function handleSortByArea() {
    setAreaReverse(!areaReverse);
    const sorted = employees.sort((a, b) =>
      a.rubro.toLowerCase().localeCompare(b.rubro.toLowerCase())
    );

    if (areaReverse) {
      setEmployees([...sorted].reverse());
      return;
    }
    setEmployees([...sorted]);
  }

  function checked() {
    const allListed = new Set(filteredEmployees.map((user) => user.id * 1));
    if (isContained(allListed, multipleSelected)) {
      return true;
    }
    return false;
  }

  function isContained(largeSet, smallerSet) {
    for (const element of largeSet) {
      if (!smallerSet.has(element)) {
        return false;
      }
    }
    return true;
  }

  function handleCheckHeader() {
    if (checked()) {
      setMultipleSelected(new Set());
      return;
    }

    const toSelect = new Set(filteredEmployees.map((user) => user.id));
    const merged = new Set([...multipleSelected, ...toSelect]);
    setMultipleSelected(merged);
    return;
  }

  return (
    <thead className=" border-b">
      <tr>
        <th scope="col" className="py-3">
          <div className="flex justify-center">
            {checked() ? (
              <BiCheckboxChecked
                onClick={handleCheckHeader}
                className="p-1 box-content cursor-pointer"
                size={24}
              />
            ) : (
              <BiCheckbox
                onClick={handleCheckHeader}
                className="p-1 box-content cursor-pointer"
                size={24}
              />
            )}
          </div>
        </th>
        <th scope="col" className="pl-2 pr-6 py-3">
          <div className="flex items-center font-medium">
            Usuario
            <FaSort
              onClick={handleSortByUsername}
              className="ml-1 box-content p-1 cursor-pointer"
            />
          </div>
        </th>
        <th scope="col" className="px-6 py-3">
          <div className="flex items-center font-medium">
            Rubro
            <FaSort
              onClick={handleSortByArea}
              className="ml-1 box-content p-1 cursor-pointer"
            />
          </div>
        </th>
        <th scope="col" className="px-6 py-3 w-fit">
          <div className="flex items-center font-medium text-center justify-center">
            Habilitar edición
          </div>
        </th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Editar</span>
        </th>
      </tr>
    </thead>
  );
}
export function TableRowNoResults() {
  return (
    <tr>
      <td colSpan={99} className="py-4 font-semibold text-center">
        No se encontró ningún integrante
      </td>
    </tr>
  );
}
