import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
} from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingButton from "../../../components/LoadingButton";
import { formatDateForInput } from "../../../utils/licenseDate";
import { extendLicense } from "../../admin/Api/AdminServices";

export function ExtendLicensePopOver(props) {
  const { licenciaId, startDate, finalDate, onComplete } = props;

  let date1 = formatDateForInput(startDate);
  let date2 = formatDateForInput(finalDate);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      startLicenseDate: date1,
      finalLicenseDate: date2,
    },
  });

  const token = useSelector((state) => state.login.token);

  const [isLoading, setIsLoading] = useState(false);

  async function handleExtendLicense(data) {
    setIsLoading(true);
    data.id = licenciaId;
    extendLicense(data, token)
      .then((response) => {
        onComplete(response.data);
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error actualizando licencia: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  // TODO: Corregir valores de formulario reseteados (visualmente) al abrir el popover una 2da vez

  return (
    <PopoverPortal>
      <PopoverContent className="rounded-md w-[255px]  bg-white border border-gray-400">
        <div className="text-sm">
          <p className="px-4 pt-4 font-medium">Extender licencia</p>
          <div className="mt-2 px-4 pb-4">
            <form
              onSubmit={handleSubmit(handleExtendLicense)}
              className="flex flex-col"
            >
              <div className="flex flex-col">
                <label> Inicio el </label>
                <input
                  {...register("startLicenseDate", {
                  })}
                  type="date"
                  defaultValue={date1}
                  className="px-2 py-1 border border-stone-400 disabled:bg-stone-200 text-stone-700"
                  disabled
                />
              </div>
              <div className="mt-2 flex flex-col">
                <label> Vence el </label>
                <input
                  {...register("finalLicenseDate", {
                  })}
                  type="date"
                  min={date1}
                  defaultValue={date2}
                  className="px-2 py-1 border outline-none focus:border-stone-800 border-stone-400 "
                />
              </div>
              <div className="mt-4 grid grid-cols-2 gap-4">
                <PopoverClose asChild>
                  <button className="px-2 py-1 rounded-md bg-stone-300">
                    Cerrar
                  </button>
                </PopoverClose>
                <LoadingButton
                  className="px-2 py-1 rounded-md bg-stone-950 text-stone-200"
                  isLoading={isLoading}
                >
                  Actualizar
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </PopoverContent>
    </PopoverPortal>
  );
}
