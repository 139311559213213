import { useState } from "react";

import {
  Toast,
  ToastAction,
  ToastClose,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@radix-ui/react-toast";

import { AiOutlineClose } from "react-icons/ai";
import "./adminComponentStyles.css";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { MultipleCardEditionModal } from "./MultipleCardEditionModal";
import { MultipleAreaEditionModal } from "./MultipleAreaEditionModal";

export function MultipleSelectedToast(props) {
  const { open, employeesSelected } = props;
  const { setOpen, setEmployeesAreaBySelected, setMultipleSelected } = props;

  const sizeSelected = employeesSelected.size;

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }
  function reloadFormState2(open) {
    if (open) return;
    setModalId2(Math.random().toString(36).substring(2, 10));
  }

  const [modalId, setModalId] = useState("FDREGF");

  const [modalId2, setModalId2] = useState("DSFGRE");

  function handleUnselectAll() {
    setMultipleSelected( new Set() )
  }

  return (
    <ToastProvider swipeDirection="right">
      <Toast
        className="toast-animation ml-8 px-4 py-4 z-20 border-t-2 isolate shadow-md flex flex-col items-start border-stone-800 bg-white"
        open={open}
        onOpenChange={setOpen}
        onSwipeEnd={(event) => {
          event.preventDefault();
        }}
      >
        <ToastTitle className="font-medium text-sm text-stone-900">
          Edición rápida - {sizeSelected} seleccionado
          {sizeSelected === 1 ? "" : "s"}
        </ToastTitle>
        <ToastAction className="mt-2" asChild altText="Editar">
          <Dialog key={modalId} modal onOpenChange={reloadFormState}>
            <DialogTrigger asChild>
              <button className="mt-2 py-1 text-sm font-medium rounded-md text-blue-500 hover:underline">
                Editar tarjeta
              </button>
            </DialogTrigger>
            <MultipleCardEditionModal employeesSelected={employeesSelected} />
          </Dialog>
        </ToastAction>
        <ToastAction asChild altText="Editar">
          <Dialog key={modalId2} modal onOpenChange={reloadFormState2}>
            <DialogTrigger asChild>
              <button className="py-1 text-sm font-medium rounded-md text-blue-500 hover:underline">
                Editar rubro
              </button>
            </DialogTrigger>
            <MultipleAreaEditionModal
              setEmployeesAreaBySelected={setEmployeesAreaBySelected}
              employeesSelected={employeesSelected}
            />
          </Dialog>
        </ToastAction>

        <ToastClose asChild>
          <button
            onClick={handleUnselectAll}
            className="p-1 top-1 right-1 box-border absolute rounded-full text-stone-600 hover:bg-stone-200"
          >
            <AiOutlineClose />
          </button>
        </ToastClose>
      </Toast>
      <ToastViewport className="max-w-[100vw] w-80 bottom-20 sm:bottom-4 right-4 fixed " />
    </ToastProvider>
  );
}
