import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { AiOutlineUserDelete } from "react-icons/ai";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { updateUserAccountData } from "../Api/superUserService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export function SimpleEditUserModal(props) {
  const { editUserModal, setEditUserModal, onCompleteEdition } = props;
  const token = useSelector((state) => state.login.token);

  const customUser = editUserModal?.custom_user;

  const [loading, setLoading] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (customUser) {
      setValue("is_admin", customUser?.is_admin);
      setValue("is_superuser", customUser?.is_superuser);
      setValue("is_sponsor", customUser?.is_sponsor);
      setValue("licencia_id", editUserModal?.licencia?.id);

    }
  }, [customUser]);

  function handleEditUser(data) {
    setLoading(true);
    data.user_id = editUserModal.custom_user.id;

    updateUserAccountData(data, token)
      .then((response) => {
        toast.success("Usuario editado exitosamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        editUserModal.custom_user = response;
        editUserModal.licencia.id = response.licencia_id
        onCompleteEdition(editUserModal);
      })
      .catch(() => {
        toast.error("Ocurrió un error al actualizar el usuario", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleCloseModal() {
    setEditUserModal(undefined);
  }

  return (
    <DialogPortal>
      <DialogOverlay className="fixed z-40 bg-amber-950/10 inset-0" />
      <DialogContent className="h-fit sm:max-w-screen-sm max-w-[640px] w-full z-50 right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 rounded-md fixed border bg-white shadow-lg">
        <div className="px-7 py-6 h-full w-full font-montserrat">
          <div className="flex items-center">
            <AiOutlineUserDelete size={24} />
            <p className="ml-2 font-medium"> Editar usuario </p>
          </div>

          <form
            onSubmit={handleSubmit(handleEditUser)}
            className="mt-4 gap-4 text-sm flex flex-col"
          >
            
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <p className="text-stone-800"> Roles de usuario</p>
                <div className="px-4 mt-2 flex">
                  <input
                    type="checkbox"
                    id="checkbox_is_admin"
                    {...register("is_admin")}
                    className={`outline-none`}
                  />
                  <label className="ml-2" htmlFor="checkbox_is_admin">
                    Administrador
                  </label>
                </div>

                <div className="px-4 mt-3 flex">
                  <input
                    type="checkbox"
                    id="checkbox_is_superuser"
                    {...register("is_superuser")}
                    className={`outline-none`}
                  />
                  <label className="ml-2" htmlFor="checkbox_is_superuser">
                    Super Admin
                  </label>
                </div>
              </div>

              <div className="px-4 mt-2 flex">
                  <input
                    type="checkbox"
                    id="checkbox_is_sponsor"
                    {...register("is_sponsor")}
                    className={`outline-none`}
                  />
                  <label className="ml-2" htmlFor="checkbox_is_sponsor">
                    Asociado
                  </label>
                </div>

              <div className="col-span-2">
                <label className="text-stone-800">Id de licencia</label>
                <input
                  type="text"
                  {...register("licencia_id", {
                    pattern: {
                      value: /^\d+$/,
                    },
                  })}
                  className={`mt-1 px-4 py-2 border outline-none w-full ${
                    errors.licencia_id
                      ? "border-red-400"
                      : "focus:border-stone-700"
                  }`}
                />
              </div>
            </div>


            <div className="grid grid-cols-2 gap-4">
              <DialogClose asChild>
                <button
                  onClick={handleCloseModal}
                  type="button"
                  className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-stone-200 text-stone-800"
                >
                  Cancelar
                </button>
              </DialogClose>
              <LoadingButton
                className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-emerald-600 text-white"
                isLoading={loading}
                color="text-transparent"
              >
                Editar usuario
              </LoadingButton>
            </div>
          </form>
        </div>
      </DialogContent>
    </DialogPortal>
  );
}
