import { useEffect, useRef, useState } from "react";
import {
  consultTransactionById,
  requestPaymentMethod,
} from "../../admin/Api/UserServices";
import { useSelector } from "react-redux";
import LoadingSpinnerV2 from "../../../components/LoadingSpinnerV2";

const SCRUM_PAYMENT_STATUS = {
  0: "AUTORIZADA",
  1: "RECHAZADA",
  2: "FRAUDE",
  3: "TECNICO",
  4: "INSUFICIENTE",
  5: "REJECT",
  6: "HONOR",
  7: "RETENIDO",
  8: "PENDIENTE",
};

export function PaymentSCRUM(props) {
  const token = useSelector((state) => state.login.token);

  const [iframeSCRUM, setIframeSCRUM] = useState(undefined);

  const [iframeLoading, setIframeLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(null);

  const { products, paymentInfo } = props;

  async function requestPayment() {
    requestPaymentMethod(paymentInfo, products, token)
      .then((response) => {
        setIframeSCRUM(response.data.solicitud_pago);
        setTransactionId(response.data.id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }

  function handleIframeLoaded() {
    setIframeLoading(false);
  }

  useEffect(() => {
    requestPayment();
  }, []);
  return (
    <div className="flex justify-center">
      <div className="w-[400px] h-[500px] grid place-items-center">
        {iframeLoading ? <LoadingSpinnerV2 size={40} /> : <></>}

        {iframeSCRUM ? (
          <iframe
            onLoad={handleIframeLoaded}
            className={`${
              iframeLoading ? "hidden" : ""
            } w-[400px] h-[500px] overflow-hidden}`}
            title="Scrum pay - Pago"
            src={`${iframeSCRUM}`}
          ></iframe>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
