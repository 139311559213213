import React from "react";
import CardContainer from "../../components/CardContainer";
import { PetsTable } from "./Components/petsTable";
import { useState } from 'react';



export function Pets() {
  const [searchTerm, setSearchTerm] = useState('');

    return (
      <div>
        <div className="Pets">
          <div className="pt-8 w-full flex flex-col font-montserrat">
            <div className="px-8 flex justify-between">
              <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
                Mascotas
              </h2>
              
            </div>
            <div className="px-8 flex justify-between">  
          </div>
            <div className="mt-4 px-8 w-full">
              <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">
                <div className="w-full sm:max-w-[24rem]">
                  <label className="text-sm text-gray-700"> Buscar </label>
                  <div className="relative">
                    <input
                      className="pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md w-full sm:min-w-[24rem] border border-gray-300 focus:border-gray-600 bg-white"
                      type="text"
                      placeholder="Buscar"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>

                
              </div>
            </div>
            <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
              <CardContainer className="px-6 py-4 shadow-md">
                <PetsTable  searchTerm={searchTerm} />
                <div className="mt-2 overflow-x-auto relative sm:rounded-lg">  
                </div>
              </CardContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
  