import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";

import LoadingButton from "../../../../components/LoadingButton";
import UndefinedImage from "../../../../assets/images/social_media_icons/icon-undefined-background.jpg";
import PlaceholderImage from "../../../../assets/images/social_media_icons/icons8-image-96.png";
import { compressImage, uploadImage } from "../../../../utils/uploadImages";


const AddImageModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal } = props;
  const { title } = props;

  const titleType = title ?? "Agregar imagen";
  const fileRefIcon = useRef(null);
  const fileRefContent = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [imageSource, setImageSource] = useState(PlaceholderImage);
  const [imageFormData, setImageFormData] = useState(null);

  const [imageSourceContent, setImageSourceContent] = useState(UndefinedImage);
  const [imageFormDataContent, setImageFormDataContent] = useState(null);

  const { register, handleSubmit } = useForm();

  const { onSubmit, onComplete } = props;
  if(!onSubmit) {
    console.log("'onSubmit' no debe ser nulo")
  }
  if(!onComplete) {
    console.log("'onComplete' no debe ser nulo")
  }

  function closeModal() {
    setShowModal(false);
  }

  async function handleAddImageLink(data) {
    setIsLoading(true);
    data.type= "image"
    data.url = "/media/custom_social_media/undefined.png"
    data.image = imageFormData
    data.imageQR = imageFormDataContent
    onSubmit(data, token).then( (responseImg) => {
      onComplete(responseImg.data)
      closeModal();
    }).catch((error) => {
      window.alert(error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  function handleOpenFilePicker(fileRef) {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function handleImageUpload(
    event,
    setImageSource,
    setImageFormData,
    option = ""
  ) {
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);

      let maxSizeMB;
      let maxWidthOrHeight;
      if (option === "compress-less") {
        maxSizeMB = 1;
        maxWidthOrHeight = 2000;
      }
      const file = await compressImage(files, maxSizeMB, maxWidthOrHeight);
      setImageFormData(file);
    });
  }

  return (
    <>
      <p className="font-medium"> {titleType} </p>
      <input
        ref={fileRefIcon}
        onChange={(e) => handleImageUpload(e, setImageSource, setImageFormData)}
        className="hidden"
        accept="image/x-png,image/jpeg"
        type="file"
      />
      <input
        ref={fileRefContent}
        onChange={(e) =>
          handleImageUpload(
            e,
            setImageSourceContent,
            setImageFormDataContent,
            "compress-less"
          )
        }
        className="hidden"
        accept="image/x-png,image/jpeg"
        type="file"
      />
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddImageLink)}
      >
        <label className="font-medium text-stone-800" htmlFor="title">
          Título
        </label>
        <input
          className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
          type="text"
          id="title"
          {...register("title")}
          placeholder="Título"
          required={true}
        />
        <label className="mt-4 text-stone-800 font-medium" htmlFor="title">
          Icono
        </label>
        <div className="flex items-center">
          <div
            className="filter hover:contrast-[0.9]"
            onClick={() => handleOpenFilePicker(fileRefIcon)}
          >
            <img
              className="max-w-[4rem] h-16 aspect-square object-cover rounded-md cursor-pointer"
              alt={`Añadir foto`}
              src={imageSource}
            />
          </div>
        </div>
        <label className="mt-4 text-stone-800 font-medium" htmlFor="title">
          Imagen
        </label>

        <div className="mt-1 flex items-center">
          <div
            className="filter hover:contrast-[0.9]"
            onClick={() => handleOpenFilePicker(fileRefContent)}
          >
            <img
              className="max-w-[4rem] h-16 aspect-square object-cover rounded-md cursor-pointer"
              alt={`Añadir foto`}
              src={imageSourceContent}
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              disabled={!imageFormDataContent}
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
            >
              Agregar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddImageModal;
