import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose, AiOutlineUserAdd } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "../../../components/LoadingButton";
import { createManyUsers } from "../Api/superUserService";

export function CreateEmployeesModal(props) {
  const token = useSelector((state) => state.login.token);

  const { licencia } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fecha_inicio: new Date().toISOString().substring(0, 10),
    },
    mode: "onChange",
  });
  async function handleCreateUsers(data) {
    data.licencia = licencia.id;
    setIsLoading(true);
    createManyUsers(data, token)
      .then((response) => {
        toast.success("Usuarios creados exitosamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        if (closeRef) {
          closeRef.current.click();
        }
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [isLoading, setIsLoading] = useState(false);
  const closeRef = useRef(null);
  return (
    <DialogPortal>
      <DialogOverlay className="fixed z-40 bg-stone-950/10 inset-0" />
      <DialogContent className="h-fit sm:max-w-screen-sm max-w-[640px] w-full z-50 right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 rounded-md fixed border border-stone-300 bg-white shadow-lg">
        <div className="px-7 py-6 h-full w-full font-montserrat">
          <div className="flex items-center">
            <AiOutlineUserAdd size={24} />
            <p className="ml-2 font-medium"> Agregar colaboradores </p>
          </div>
          <form
            onSubmit={handleSubmit(handleCreateUsers)}
            className="mt-4 gap-4 text-sm flex flex-col"
          >
            <div>
              <label className="text-stone-800">
                Usuario inicial
              </label>
              <input
                type="text"
                {...register("correo", {
                  required: true,
                  pattern: {
                    message: "error",
                    value: /^[a-zA-Z0-9ñÑ](?!.*[._-]{2})[ñÑa-zA-Z0-9._-]*$/,
                  },
                })}
                className={`mt-1 px-4 py-2 border outline-none w-full ${
                  errors.correo ? "border-red-400" : "focus:border-stone-700"
                }`}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">Cantidad de usuarios</label>
                <input
                  className={`mt-1 px-4 py-2 border outline-none w-full ${
                    errors.cant ? "border-red-400" : "focus:border-stone-700"
                  }`}
                  type="text"
                  {...register("cant", {
                    valueAsNumber: true,
                    validate: (value) => {
                      return value > 0;
                    },
                  })}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">Id de licencia</label>
                <input
                  className={`mt-1 px-4 py-2 border bg-stone-100 text-stone-600 outline-none w-full ${
                    errors.licencia
                      ? "border-red-400"
                      : "focus:border-stone-700"
                  }`}
                  type="text"
                  disabled
                  {...register("licencia")}
                  defaultValue={licencia.id}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <DialogClose asChild>
                <button
                  ref={closeRef}
                  type="button"
                  className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-stone-200 text-stone-800"
                >
                  Cancelar
                </button>
              </DialogClose>

              <LoadingButton
                className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-emerald-600 text-white"
                isLoading={isLoading}
                color="text-transparent"
                type="submit"
              >
                Crear usuarios
              </LoadingButton>
            </div>
          </form>
        </div>
        <DialogClose asChild>
          <button type="button" className="top-5 right-5 absolute">
            <AiOutlineClose
              className="p-[6px] rounded-full box-content hover:bg-stone-100"
              size={20}
            />
          </button>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
}
