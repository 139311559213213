import React, { useState } from "react";

import TuMascotaLogoColor from "../../src/assets/images/tumascota-full-color.png";

import TuMascotaHuella from "../assets/images/logo-tu-mascota-white.png";

import { FaCog, FaUser, FaChartBar, FaPencilAlt, FaHeadset } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { SupportModal } from "./Modals/SupportModal";
import { MdHeadset } from "react-icons/md";

export const Option = {
  PROFILE: 0,
  EDIT_CARD: 1,
  METRICS: 2,
  SETTINGS: 3,
};

const SidebarButton = (props) => {
  const { icon, title, selected, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      onClick={handleClick}
      className={` ${
        selected ? "font-medium text-white bg-orange-cake" : "text-stone-600/95 hover:bg-stone-200/60"
      }  mx-0 sm:mx-4 font-medium rounded-none sm:rounded-lg flex-1 py-3 sm:py-[10px] sm:px-4 sm:pl-2 hover:cursor-pointer`}
    >
      <div className={`items-center flex flex-col sm:flex-row`}>
        {<div className="sm:ml-2"> {icon} </div>}
        <h2 className="mt-1 text-base sm:mt-0 sm:ml-4 text-ellipsis whitespace-nowrap">{title}</h2>
      </div>
    </div>
  );
};

export const Sidebar = (props) => {
  const { selected, setSidebarOption } = props;
  const { logout } = props;

  const defaultIconSize = 16;

  const [showSupportModal, setShowSupportModal] = useState(false);

  return (
    <div className="sidebar-shadow z-30 sm:z-[5] inset-0 top-auto shadow-lg fixed text-xl sm:w-56 sm:top-0 sm:right-auto bg-white">
      <div className="sm:flex sm:flex-col sm:h-full sm:justify-between">
        <div className="sm:mt-[5vh]">
          <div className="py-4 mx-auto w-full bg-white">
            <img className="hidden h-16 object-contain mx-auto sm:block" alt="tumascota icon" src={TuMascotaLogoColor} />
          </div>
          <div className="flex flex-1 gap-0 sm:gap-2 sm:flex-none sm:mt-8 sm:flex-col">
            <p className="hidden sm:block text-stone-50 font-bold text-sm px-8"> Menú </p>
            <SidebarButton
              onClick={() => setSidebarOption(Option.PROFILE)}
              selected={selected === Option.PROFILE}
              icon={<FaUser size={defaultIconSize} />}
              title="Inicio"
            />
            <SidebarButton
              onClick={() => setSidebarOption(Option.EDIT_CARD)}
              selected={selected === Option.EDIT_CARD}
              icon={<FaPencilAlt size={defaultIconSize} />}
              title="Editar"
            />
            <SidebarButton
              onClick={() => setSidebarOption(Option.METRICS)}
              selected={selected === Option.METRICS}
              icon={<FaChartBar size={defaultIconSize} />}
              title="Métricas"
            />
            <SidebarButton
              onClick={() => setSidebarOption(Option.SETTINGS)}
              selected={selected === Option.SETTINGS}
              icon={<FaCog size={defaultIconSize} />}
              title="Opciones"
            />
          </div>
        </div>

        <div className="hidden relative sm:block">
          <img
            className="absolute -top-full left-1/2 rotate-12 -translate-x-1/2 opacity-20 w-16"
            alt="_"
            src={TuMascotaHuella}
          ></img>
          <div className="mx-auto mb-4 w-3/4 h-[2px] rounded-md bg-orange-cake"></div>
          <div className="mb-4">
          <SidebarButton
              onClick={() => setShowSupportModal(true)}
              selected={false}
              icon={<MdHeadset size={defaultIconSize} />}
              title="Soporte"
            />
            <SupportModal
              setShowModal={setShowSupportModal}
              showModal={showSupportModal}
            />
            <SidebarButton
              onClick={() => logout()}
              selected={false}
              icon={<FiLogOut size={defaultIconSize} />}
              title="Cerrar sesión"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
