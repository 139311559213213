import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import CardContainer from "../../components/CardContainer";
import { getAllCompanies } from "./Api/superUserService";
import { CreateUserModal } from "./Components/CreateUserModal";
import { UsersTableHeader, UserTableRow } from "./Components/UsersTable";
import { UserLoadingTableRow } from "./Components/UsersTableLoading";
import { SimpleEditUserModal } from "./Components/SimpleEditUserModal";

export function SuperUsers(props) {
  const token = useSelector((state) => state.login.token);
  const { setUserPersonified } = props;

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    username: "",
  });

  const [modalId, setModalId] = useState("ABCDEFGH");
  const [modalId2, setModalId2] = useState("ABCDEFGH123");
  const [editUserModal, setEditUserModal] = useState(undefined);

  async function loadUsers() {
    getAllCompanies(token)
      .then((response) => {
        const usersArray = Object.values(response.data);
        setUsers(usersArray);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function setUserByIndex(index) {
    return (user) => {
      users[index] = user;
      setUsers([...users]);
    };
  }
  function renderUserResults(results) {
    const list = results.map((user, index) => (
      <UserTableRow
        key={user.custom_user.id}
        setUser={setUserByIndex(index)}
        userData={user}
        setUserPersonified={setUserPersonified}
        setEditUserModal={setEditUserModal}
        index={index}
      />
    ));
    return list;
  }

  function filterUsers(users) {
    if (!users) {
      return undefined;
    }
    let query = filters.username.toLowerCase();

    let [typeQuery, search] = query.split(":");

    if (!search) {
      typeQuery = undefined;
    }
    switch (typeQuery) {
      case "id":
        return users.filter((e) =>
          e.licencia.id.toString().toLowerCase().includes(search)
        );
      case "username":
        return users.filter((e) =>
          e.custom_user.username.toLowerCase().includes(search)
        );
      case "email":
        return users.filter((e) =>
          e.custom_user.email.toLowerCase().includes(search)
        );
      default:
        return users.filter((e) =>
          e.custom_user.username.toLowerCase().includes(query)
        );
    }
  }

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 300);
    };
  };

  const handleChange = (value) => {
    setFilters({ ...filters, username: value });
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  function handleSimpleEditUserModalOnComplete(user) {
    const updateUser = setUserByIndex(user.index);
    updateUser(user);
  }

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }
  function reloadFormState2(open) {
    if (!open) {
      setEditUserModal(undefined);
      setModalId2(Math.random().toString(36).substring(2, 10));
    }
  }

  useEffect(() => {
    loadUsers();
  }, []);
  let memoizedUserRows = [];

  memoizedUserRows = useMemo(
    () => renderUserResults(filterUsers(users)),
    [filters, isLoading, users]
  );

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Usuarios de TuMascota
        </h2>
        <Dialog key={modalId} onOpenChange={reloadFormState}>
          <DialogTrigger asChild>
            <button className="px-3 py-1 shadow-md font-medium rounded-md hover:bg-emerald-500/90 bg-emerald-500 text-white">
              Agregar usuario
            </button>
          </DialogTrigger>
          <CreateUserModal />
        </Dialog>
      </div>
      <div className="mt-4 px-8 w-full">
        <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">
          <div className="w-full sm:max-w-[24rem]">
            <label className="text-sm text-gray-700"> Buscar </label>
            <div className="relative">
              <input
                className="pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md w-full sm:min-w-[24rem] border border-gray-300 focus:border-gray-600 bg-white"
                type="text"
                placeholder="Buscar"
                onChange={(e) => optimizedFn(e.target.value)}
              />
              <AiOutlineSearch className="absolute left-1 top-1/2 -translate-y-1/2 cursor-pointer  text-stone-400" />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-700"> Tipo de usuario </label>
            <select className="px-2 py-[6px] rounded-md bg-white border border-gray-300 foc focus:border-gray-600">
              <option value="todos"> Todos </option>
              <option value="admin"> Administrador </option>
              <option value="users"> Usuarios </option>
              <option value="influencer"> Influencer </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-700"> Vencen en </label>
            <select className="px-2 py-[6px] rounded-md bg-white border border-gray-300 foc focus:border-gray-600">
              <option value="todos"> --- </option>
              <option value="5"> 1 semana </option>
              <option value="1"> 1 mes </option>
              <option value="2"> 2 meses </option>
              <option value="3"> 3 meses </option>
              <option value="3"> 1 año </option>
            </select>
          </div>
        </div>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          <div className="w-full flex items-center justify-between">
            <h2 className="font-medium"> Usuarios de TuMascota </h2>
          </div>
          <div className="mt-2 overflow-x-auto relative sm:rounded-lg">
            <table className="w-full text-sm text-left text-stone-800">
              <Dialog
                key={modalId2}
                open={editUserModal}
                onOpenChange={reloadFormState2}
              >
                <SimpleEditUserModal
                  editUserModal={editUserModal}
                  onCompleteEdition={handleSimpleEditUserModalOnComplete}
                  setEditUserModal={setEditUserModal}
                />
              </Dialog>
              <UsersTableHeader />
              <tbody>
                {isLoading ? <UserLoadingTableRow /> : memoizedUserRows}
              </tbody>
            </table>
          </div>
        </CardContainer>
      </div>
    </div>
  );
}
