import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { useState, memo } from "react";
import { FaSort } from "react-icons/fa";
import { toast } from "react-toastify";
import { IMAGE_DOMAIN } from "../../../config/Constant";
import { getUserInitials } from "../../../utils/getUserInitials";
import { generateRandomKey } from "../../../utils/generateRandomKey";
import { addDurationToDate, formatDate } from "../../../utils/licenseDate";
import { CreateEmployeesModal } from "./CreateEmployeesModal";
import { ExtendLicensePopOver } from "./ExtendLicensePopOver";
import { UserRolTag } from "./UserRolTag";
import "../Components/adminComponentStyles.css";
import { CreateDiscountModal, ModalComponent } from './CreateDiscountModal';

function licencePercentageCompleted(date1, date2) {
  const diff = date1.getTime() - date2.getTime();
  const daysTotal = diff / (1000 * 60 * 60 * 24);

  const today = new Date();
  const diffCompleted = date2.getTime() - today.getTime();
  const daysCompleted = Math.abs(diffCompleted / (1000 * 60 * 60 * 24));
  const percentageCompleted = Math.min(daysCompleted, daysTotal) / daysTotal;

  if (percentageCompleted < 0.1) {
    return 0;
  }
  return percentageCompleted;
}

// TODO: Remover clase innecesaria
export class User {
  licencia = {
    id: 0,
    custom_user_admin: 0,
    tipo_de_plan: "",
    fecha_fin: "",
    fecha_inicio: "",
    duracion: 0,
    status: 0,
    date_joined: "",
  };

  custom_user = {
    id: 0,
    email: "",
    username: "",
    rubro: "",
    is_editable: false,
    is_active: false,
    is_sponsor: false,
  };

  profile = {
    id: 0,
    public_name: "",
    image: "",
  };
  constructor(object) {
    this.licencia = object.licencia;
    this.custom_user = object.custom_user;
    this.profile = object.profile;
  }
}



export function UserTableRow(props) {
  let { userData, setUser, setUserPersonified, setEditUserModal, index ,onSelectUser} = props;
  let user = new User(userData);

  const startDate = new Date(user.licencia.fecha_inicio);
  const duration = user.licencia.duracion;
  const finalDate = addDurationToDate(startDate, duration);

  function onCompleteExtendLicense(licencia) {
    userData.licencia = licencia;
    setUser(userData);

    toast.success("Licencia modificada exitosamente", {
      position: "top-center",
      bodyClassName: "text-sm",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  function handlePersonifyUser() {
    setUserPersonified(user.custom_user);
  }

  function handleEditUserModal() {
    user.index = index; // Pq? Para acceder la fila a la que pertenece
    console.log(user)
    setEditUserModal(user);

    onSelectUser(user.custom_user.id);
  }

  const percentageCompleted = licencePercentageCompleted(finalDate, startDate);

  const [modalId, setModalId] = useState(generateRandomKey());

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }
 const [inputValue,setInputValue]=useState('');

  function handleCreateButtonClick(userId,username,is_admin) {
    console.log(`Botón "Crear" presionado para el usuario con ID: ${userId} y nombre: ${username} sponsor: ${is_admin}` );
    // Aquí puedes agregar lógica adicional relacionada con el botón "Crear"
    setModalId(userId,username);
  }

  return (
    <tr className="border-b border-stone-200 text-stone-900 hover:bg-stone-100">
      <td className="pl-2 pr-2 py-4 flex items-center whitespace">
        <Avatar className="mr-4 h-10 w-10 pointer-events-none select-none text-sm inline-flex justify-center items-center text-center rounded-full bg-stone-200">
          <AvatarImage
            className="rounded-full aspect-square object-cover"
            loading="lazy"
            src={`${IMAGE_DOMAIN}${user.profile.image}`}
          />
          <AvatarFallback>
            {getUserInitials(
              user.profile.public_name,
              user.custom_user.username
            )}
          </AvatarFallback>
        </Avatar>



        <div className="flex flex-col">
          <a
            href={`http://soy.tumascota.digital/u/${user.custom_user.username}`}
            target="_blank"
            rel="noreferrer"
            className=" inline-flex font-medium hover:underline"
          >
            {`${user.custom_user.username} (${user.custom_user.id})`} 
          </a>
          <div className="text-xs text-stone-600">{user.custom_user.email}</div>
        </div>
      </td>

      <td className="px-2 py-4">
        <div className="capitalize">{user.licencia.tipo_de_plan} {user.custom_user.is_admin} </div>
      </td>
      <td className="px-2 py-4 text-center">
        <div className="capitalize">{user.licencia.id}</div>
      </td>
      <td className="px-2 py-4">
        <Popover>
          <PopoverTrigger>
            <div className="px-3 py-1 w-48 cursor-pointer isolate relative rounded-xl text-center whitespace-nowrap font-medium bg-green-500 text-white">
              <div>{`${formatDate(startDate)} - ${formatDate(finalDate)}`}</div>
              <div
                style={{
                  width: `${percentageCompleted * 100}%`,
                }}
                className={`${
                  percentageCompleted > 0.99 ? "bg-red-600" : "bg-green-600"
                } absolute inset-0 right-auto rounded-xl -z-10`}
              ></div>
            </div>
          </PopoverTrigger>
          <ExtendLicensePopOver
            startDate={startDate}
            finalDate={finalDate}
            licenciaId={user.licencia.id}
            onComplete={onCompleteExtendLicense}
          />
        </Popover>
      </td>
      <td className="px-2 py-4">
        <UserRolTag user={user} />
      </td>
      <td className="px-2 py-4">
        <div>{formatDate(new Date(user.custom_user.date_joined))}</div>
      </td>
      <td className="px-2 py-4 font-medium whitespace-nowrap">
        <button
          onClick={handleEditUserModal}
          className="cursor-pointer text-blue-500 hover:underline"
        >
          Rol
        </button>

        <button
          onClick={handlePersonifyUser}
          className=" ml-4 cursor-pointer text-blue-500 hover:underline"
        >
          Editar
        </button>

        <Dialog key={modalId} onOpenChange={reloadFormState}>
          <DialogTrigger asChild>
            <button className="ml-4 cursor-pointer text-blue-500 hover:underline">
              Crear
            </button>
          </DialogTrigger>
          <CreateEmployeesModal licencia={user.licencia} />
        </Dialog>

        {user.custom_user.is_sponsor && (
          <button
            onClick={() => handleCreateButtonClick(user.custom_user.id, user.custom_user.username, user.custom_user.is_sponsor)}
            className="ml-4 cursor-pointer text-blue-500 hover:underline"
          >
            <CreateDiscountModal userId={user.custom_user.id} username={user.custom_user.username} />
          </button>
        )}

      
      </td>
    </tr>
  );
}

export function UsersTableHeader() {
  return (
    <thead className="border-b">
      <tr>
        <th scope="col" className="pl-2 pr-2 py-3">
          <div className="flex items-center font-medium">
            Usuario
            <FaSort className="ml-1 box-content p-1 cursor-pointer" />
          </div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Plan</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium whitespace-nowrap">
            Id. licencia
          </div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Licencia</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Rol</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Fecha creado</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Acciones</div>
        </th>
      </tr>
    </thead>
  );
}
