import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { HiCursorClick, HiDeviceMobile, HiUsers } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

import { PieChart } from "react-minimal-pie-chart";
import { BiFilter } from "react-icons/bi";

import {
  countClicks,
  highestNByAttribute,
  mergeStats,
  mergeViewsByArea,
  mostClickedLinksAdmin,
  sortStatsByCounter,
} from "../../utils/analyticsUtils";
import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";
import CardContainer from "../../components/CardContainer";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { FilterAnalyticsModal } from "./Components/FilterAnalyticsModal";
import { BarChart } from "./Components/BarChart";
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { getUserInitials } from "../../utils/getUserInitials";
import { generateRandomKey } from "../../utils/generateRandomKey";
import { DOMAIN, IMAGE_DOMAIN } from "../../config/Constant";

function FilterItem(props) {
  const { value, type, setFilter, filter } = props;

  function mulberry32(a) {
    // Solo lo uso para dar un color único aleatorio
    return function () {
      let t = (a += 0x6d2b79f5);
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
  }

  function calculateSeed(value) {
    let total = 0;
    for (let char of value) {
      total += char.charCodeAt();
    }
    return total;
  }

  function deleteFilter() {
    if (type === "user") {
      filter.byUser.delete(value);
    }

    if (type === "area") {
      filter.byArea.delete(value);
    }
    setFilter({ ...filter });
  }

  const random = mulberry32(calculateSeed(value));

  const r = 180 + Math.floor(random() * 60);
  const g = 180 + Math.floor(random() * 60);
  const b = 180 + Math.floor(random() * 60);

  return (
    <div
      className="pl-1 pr-2 py-1 flex group items-center cursor-pointer rounded-lg"
      style={{ backgroundColor: `rgb(${r},${g},${b})` }}
      onClick={deleteFilter}
    >
      <button className="p-1 rounded-full hover:bg-stone-900/10 group-hover:bg-stone-900/10 box-content">
        <AiOutlineClose />
      </button>
      <div className="ml-1 text-sm">{value ? value : "No definido"}</div>
    </div>
  );
}

async function loadAllStatistics(token, userId) {
  let extra = "";
  if (userId) {
    extra = `?user_id=${userId}`;
  }
  const response = await fetch(`${DOMAIN}profile/statisticsAdm${extra}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) throw Error(response.body);

  return response.json();
}

function filterStatistics(filter, statistics) {
  if (!statistics) return;
  let filtered = statistics;
  if (filter.byArea.size > 0) {
    filtered = statistics.filter((user) => filter.byArea.has(user.rubro));
  }

  if (filter.byUser.size > 0) {
    filtered = filtered.filter((user) => filter.byUser.has(user.username));
  }

  return filtered;
}

export function SuperAdminAnalytics(props) {
  const token = useSelector((state) => state.login.token);

  const { userPersonified } = props;

  const [rawStatistics, setRawStatistics] = useState([]);
  const [filter, setFilter] = useState({
    byUser: new Set(),
    byArea: new Set(),
    showAll: true,
  });

  const [loadingActive, setLoadingActive] = useState(true);

  async function getStatistic() {
    let userId;
    if (userPersonified) {
      userId = userPersonified.id;
    }
    const response = loadAllStatistics(token, userId);

    await response
      .then((response) => {
        setRawStatistics(response.data);
        setLoadingActive(false);
      })
      .catch(() => {
        window.alert("Error cargando estadísticas");
      })
      .finally(() => {
        setLoadingActive(false);
      });
  }

  let filteredStatistics = filterStatistics(filter, rawStatistics);
  let statistics = mergeStats(filteredStatistics);

  let topClickedLinksAdmin= []
  if( filteredStatistics ) {
    topClickedLinksAdmin = mostClickedLinksAdmin(filteredStatistics, 10);
  }


  let pieChartData;
  let barChartData;

  if (!loadingActive) {
    pieChartData = getPieChartData();
    barChartData = getBarChartData();
  }

  function getPieChartData() {
    const merged = mergeViewsByArea(filteredStatistics);
    const highestFive = highestNByAttribute(merged, "counter", 4);
    const colors = [
      "#2D87BB",
      "#F66D44",
      "#FEAE65",
      "#E6F69D",
      "#AADEA7",
      "#64C2A6",
    ];
    const data = highestFive.map((link, index) => ({
      title: link.rubro,
      value: link.counter,
      color: colors[index],
    }));
    return data;
  }

  function getBarChartData() {
    const highestFive = highestNByAttribute(filteredStatistics, "counter", 4);
    const colors = [
      "#E63946",
      "#EDAE49",
      "#3376BD",
      "#64C2A6",
      "#00798C",
      "#52489C",
    ];
    const data = highestFive.map((user, index) => ({
      title: user.public_name ? user.public_name : user.username,
      value: user.counter,
      color: colors[index],
    }));
    return data;
  }

  useEffect(() => {
    getStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-8 pb-6 w-full flex flex-col font-montserrat bg-[rgb(240,240,240)]">
      <div className="px-8 flex flex-col justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Analíticas de Startup
        </h2>
        {loadingActive ? (
          <></>
        ) : (
          <>
            <Dialog>
              <DialogTrigger asChild>
                <div className="mt-3 mr-auto text-stone-600">
                  <button className="px-4 py-1 shadow-sm rounded-lg flex items-center transition-colors bg-stone-200 hover:bg-stone-300">
                    <BiFilter className="mr-1" />
                    Agregar filtros
                  </button>
                </div>
              </DialogTrigger>
              <FilterAnalyticsModal
                employees={rawStatistics}
                filter={filter}
                setFilter={setFilter}
              />
            </Dialog>
            <div className="mt-3 flex flex-wrap gap-x-2 gap-y-2">
              {[...filter.byArea].map((filterName) => (
                <FilterItem
                  key={generateRandomKey()}
                  value={filterName}
                  type="area"
                  setFilter={setFilter}
                  filter={filter}
                />
              ))}
              {[...filter.byUser].map((filterName) => (
                <FilterItem
                  key={generateRandomKey()}
                  value={filterName}
                  type="user"
                  setFilter={setFilter}
                  filter={filter}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1 lg:grid-cols-6">
        <CardContainer className="px-6 py-4 shadow-md lg:col-span-2">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <div className="p-2 w-fit rounded-lg bg-slate-100">
                <HiDeviceMobile size={20} className="text-stone-800" />
              </div>
              <h2 className="mt-1 font-medium text-stone-700">
                Cantidad de visitas
              </h2>
              <p className="mt-1 text-2xl font-bold text-stone-900">
                {statistics.statistics.counter}
              </p>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 shadow-md lg:col-span-2">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <div className="p-2 w-fit rounded-lg bg-slate-100">
                <HiCursorClick size={20} className="text-stone-800" />
              </div>
              <h2 className="mt-1 font-medium text-stone-700">
                Cantidad de clics
              </h2>
              <p className="mt-1 text-2xl font-bold text-stone-900">
                {countClicks(statistics.statistics.custom_social_list)}
              </p>
            </>
          )}
        </CardContainer>
        <CardContainer className="px-6 py-4 shadow-md lg:col-span-2">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <div className="p-2 w-fit rounded-lg bg-slate-100">
                <HiUsers size={20} className="text-stone-800" />
              </div>
              <h2 className="mt-1 font-medium text-stone-700">Colaboradores</h2>
              <p className="mt-1 text-2xl font-bold text-stone-900">
                {statistics.total}
              </p>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 pb-6 shadow-md  col-span-1 lg:col-span-4">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              {}
              <h2 className="font-medium">
                Áreas con mayores visitas ({pieChartData.length})
              </h2>

              <div className="flex flex-col sm:flex-row">
                <div className="h-36 mx-auto sm:mx-0 w-fit flex">
                  <PieChart
                    radius={45}
                    segmentsShift={(index) => (index === 0 ? 4 : 0)}
                    data={pieChartData}
                  />
                </div>

                <div className="mt-4 gap-x-6 gap-y-3 ml-4 grid grid-cols-2 sm:flex sm:flex-wrap overflow-x-auto sm:mr-auto">
                  {pieChartData.map((data, index) => (
                    <div key={generateRandomKey()}>
                      <div className="flex items-center" key={index}>
                        <div
                          className="h-[10px] rounded-full aspect-square"
                          style={{ backgroundColor: data.color }}
                        ></div>
                        <p className="ml-2 font-medium"> {data.title} </p>
                      </div>
                      <p className="ml-5 text-sm"> {data.value} visitas </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </CardContainer>
        <CardContainer className="px-6 py-4 pb-6 shadow-md hidden lg:block col-span-1 lg:col-span-2 row-span-2">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <h2 className="font-medium">
                Visitas de colaboradores ({statistics.total})
              </h2>
              <div className="max-h-96 overflow-y-auto pr-2">
                <table className=" w-full">
                  <tbody>
                    {filteredStatistics.map((user) => (
                      <tr
                        key={generateRandomKey()}
                        className="flex items-center mt-2"
                      >
                        <td className="flex items-center w-full">
                          <Avatar className="mr-4 h-8 w-8 pointer-events-none select-none text-sm inline-flex justify-center items-center text-center rounded-full bg-stone-200">
                            <AvatarImage
                              className="h-8 w-8 object-cover rounded-full"
                              src={`${IMAGE_DOMAIN}${user.image}`}
                            />
                            <AvatarFallback className="text-xs">
                              {getUserInitials(user.public_name, user.username)}
                            </AvatarFallback>
                          </Avatar>
                          <div>
                            {user.public_name
                              ? user.public_name
                              : user.username}
                          </div>
                        </td>
                        <td>{user.counter}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </CardContainer>
        <CardContainer className="px-6 py-4 pb-6 shadow-md  col-span-1 lg:col-span-4">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <h2 className="font-medium">
                Colaboradores destacados ({barChartData.length})
              </h2>
              <div className="flex flex-col sm:flex-row">
                <BarChart
                  className="h-36 w-fit mx-auto sm:mx-0"
                  gridColor={"rgb(87,83, 90)"}
                  barColor={"orange"}
                  data={barChartData}
                />
                <div className="mt-4 gap-x-6 gap-y-3 ml-4 grid grid-cols-2 sm:flex sm:flex-wrap overflow-x-auto sm:mr-auto">
                  {barChartData.map((data, index) => (
                    <div key={generateRandomKey()}>
                      <div className="flex items-center" key={index}>
                        <div
                          className="h-[10px] rounded-full aspect-square"
                          style={{ backgroundColor: data.color }}
                        ></div>
                        <p className="ml-2 font-medium"> {data.title} </p>
                      </div>
                      <p className="ml-5 text-sm"> {data.value} visitas </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 shadow-md col-span-full">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <div className="flex flex-col">
              <h2 className="font-medium">Enlaces más vistos ({topClickedLinksAdmin.length})</h2>

              <table className="mt-4 w-full border-spacing-y-16">
                <thead className="font-medium">
                  <tr>
                    <th className="font-medium text-left"> Nombre </th>
                    <th className="font-medium"> Interacciones </th>
                  </tr>
                </thead>
                <tbody>
                  {topClickedLinksAdmin.map(
                    (link, index) => (
                      <tr key={index}>
                        <td className="py-2 w-full flex items-center">
                          <img
                            className="ml-2 max-w-[2rem] aspect-square object-cover rounded-xl"
                            src={`${IMAGE_DOMAIN}${link.image}`}
                            alt={`Icono de ${link.title}`}
                          />
                          <div className="ml-4 flex flex-col">
                            <p className="">{link.title}</p>
                            <p className="text-xs text-stone-600">
                              de {link.user.public_name || link.user.username}
                            </p>
                          </div>
                        </td>
                        <td className="text-center"> {link.counter} </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 shadow-md col-span-full hidden">
         
            <div className="flex flex-col">
              <h2 className="font-medium">Métricas de Asociados ()</h2>

              <table className="mt-4 w-full border-spacing-y-16">
                <thead className="font-medium">
           
           
                </thead>
                <tbody>
                 CONTENIDO


<h1>Resumen Estadístico:</h1>
<p>
        Calcular estadísticas descriptivas como la media, mediana, moda y desviación estándar para el monto de descuento.
        Contar la frecuencia de cada tipo de descuento y estado (activo/inactivo).
        Analizar la distribución temporal de los cupones.</p>
<br/>
<h1>Análisis Temporal:</h1>
<p>
        Crear gráficos de líneas o barras para visualizar la distribución temporal de la emisión y uso de cupones.
        Analizar tendencias a lo largo del tiempo para identificar patrones estacionales o eventos específicos que afecten el uso de cupones.</p>
<br/>
<h1>Segmentación:</h1>
<p>
        Segmentar los datos según la red social y analizar el rendimiento de los cupones en cada plataforma.
        Segmentar por tipo de descuento para entender qué tipos son más efectivos.
        Analizar la efectividad de los cupones según su estado (activo/inactivo).
</p>
<br/>
<h1>Análisis Financiero:</h1>
<p> 
        Calcular el monto total de descuentos otorgados en un período específico.
        Evaluar el impacto financiero de los cupones activos e inactivos.
        Calcular el retorno de inversión (ROI) de la estrategia de cupones.</p>
<br/>
<h1>Mapas de Calor:</h1>
<p>
        Utilizar mapas de calor para visualizar la concentración geográfica de los usuarios que utilizan cupones.
        Identificar áreas geográficas con mayor o menor participación.</p>
<br/>
<h1>Análisis de Conversión:</h1>
<p>   
        Calcular la tasa de conversión de cupones emitidos a cupones utilizados.
        Analizar el comportamiento de los usuarios antes y después de utilizar un cupón.</p>
<br/>
<h1> Análisis de Cohortes:</h1>
<p>
        Agrupar a los usuarios en cohortes según la fecha de registro o la fecha de emisión del cupón, y analizar el comportamiento a lo largo del tiempo.</p>
<br/>
<h1> Análisis de Cancelación:</h1>
<p> 
        Analizar la tasa de cancelación de cupones y entender las razones detrás de la inactivación de los mismos.</p>
<br/>

                 

    

    
   

    

 

   

  
                </tbody>
              </table>
            </div>
          
        </CardContainer>
      </div>
    </div>
  );
}
