import { useEffect, useState } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group";

import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";

import { DOMAIN, IMAGE_DOMAIN } from "../../config/Constant";
import { UserProfileBody } from "../home/UserProfile";
import { EditCardBody } from "../home/EditCard";
import { editProfileForUser, updateUserDataAdmin } from "./Api/AdminServices";
import { useSelector } from "react-redux";
import { getUserInitials } from "../../utils/getUserInitials";
import { createCustomSocialMedia } from "./Api/UserServices";
import UserAnalytics from "../home/UserAnalytics";

async function fetchData(endpoint, token) {
  const response = await fetch(DOMAIN + endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
  return response;
}

export function EditAdminEmployee(props) {
  const { setEmployee, setStateEmployeeById, employee } = props;

  const [viewOption, setViewOption] = useState("account");
  const [isLoading, setIsLoading] = useState(true);

  const [customSocialMedia, setCustomSocialMedia] = useState();
  const [profileData, setProfileData] = useState();
  const [openPreview, setOpenPreview] = useState(false);

  const token = useSelector((state) => state.login.token);

  async function getProfile() {
    const response = await fetchData(
      `profile/userWithoutStatcis/${employee.public_id}`,
      token
    );
    const socialMediaResponse = await response.json();

    if (!response.ok) throw Error(socialMediaResponse);

    return socialMediaResponse.data;
  }

  async function loadUserProfile() {
    getProfile()
      .then((profile) => {
        setCustomSocialMedia(profile.custom_social_media);
        setProfileData(profile.profile);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function renderViewOption() {
    switch (viewOption) {
      case "account":
        return (
          <UserProfileBody
            userData={employee}
            setUserData={setEmployee}
            userId={employee.id}
            onSubmitUpdateUser={(data, token) => {
              data.id = employee.id;
              return updateUserDataAdmin(data, token);
            }}
            onComplete={setStateEmployeeById(employee.id)}
          />
        );
      case "profile":
        return (
          <EditCardBody
            setCustomSocialMedia={setCustomSocialMedia}
            customSocialMedia={customSocialMedia}
            setProfileData={setProfileData}
            profileData={profileData}
            openPreview={openPreview}
            userData={employee}
            onSubmitAboutMe={editProfileForUser}
            onSocialMediaCreateForAll={async (data, token) => {
              data.id = employee.id;
              return createCustomSocialMedia(data, token);
            }}
            editAvatarOrBackground={async (data, token) => {
              data.id = employee.id;
              return editProfileForUser(data, token);
            }}
          />
        );

      case "metrics":
        return <UserAnalytics user={employee} />;
      default:
        return <div> Raro </div>;
    }
  }

  function handleGoBack() {
    setEmployee(undefined);
  }

  function handleChangeView(value) {
    if (value) {
      setViewOption(value);
      return;
    }
  }
  useEffect(() => {
    loadUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="px-8 justify-between">
        <h2 className="pr-12 flex items-center font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          <IoIosArrowBack
            onClick={handleGoBack}
            className="p-1 cursor-pointer box-content"
          />
          <Avatar className="mr-2 h-10 w-10 select-none text-sm inline-flex justify-center items-center text-center rounded-full bg-stone-200">
            <AvatarImage
              className="rounded-full"
              src={`${IMAGE_DOMAIN}${employee.profile.image}`}
            />

            <AvatarFallback>
              {getUserInitials(employee.username, employee.username)}
            </AvatarFallback>
          </Avatar>

          <p className="ml-2 cursor-pointer">{employee.username}</p>
        </h2>
      </div>

      <div className="mt-4 mb-2 px-8 mx-auto">
        <ToggleGroup
          onValueChange={handleChangeView}
          className="rounded-md shadow-md bg-stone-50"
          type="single"
          defaultValue="account"
        >
          <ToggleGroupItem
            className={`${
              viewOption === "account"
                ? "border-stone-800"
                : "border-stone-300 text-stone-600"
            } rounded-tl-md px-4 py-2 border-b-2 transition-colors `}
            value="account"
          >
            Cuenta
          </ToggleGroupItem>
          <ToggleGroupItem
            className={`${
              viewOption === "profile"
                ? "border-stone-800"
                : "border-stone-300 text-stone-600"
            } rounded-tr-md px-4 py-2 border-b-2 transition-colors`}
            value="profile"
          >
            Perfil
          </ToggleGroupItem>
          <ToggleGroupItem
            className={`${
              viewOption === "metrics"
                ? "border-stone-800"
                : "border-stone-300 text-stone-600"
            } rounded-tr-md px-4 py-2 border-b-2 transition-colors`}
            value="metrics"
          >
            Métricas
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      {isLoading ? (
        <div className="mt-32 w-full flex justify-center">
          <LoadingSpinnerV2 size={32} />
        </div>
      ) : (
        renderViewOption()
      )}
    </>
  );
}
