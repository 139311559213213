import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaPencilAlt } from "react-icons/fa";
import { overrideUndefinedImage } from "../../../utils/overrideUndefinedImage";
import EditSocialLinkModal from "./EditSocialLinkModal";

import { IMAGE_DOMAIN, DOMAIN } from "../../../config/Constant";

import { useSelector } from "react-redux";
import EditImageTypeModal from "./LinkTypes/EditImageTypeModal";

async function toggleVisibilitySocialLink(token, link, value) {
  // TODO: Refactorizar en una carpeta de /Services o /Repositories
  const linkObject = {
    is_visible: value,
  };
  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + link.id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(linkObject),
    }
  );

  return response;
}

const EditSocialLinkButton = (props) => {
  const { index, link, setSocialMedia, deleteSocialMedia } = props;
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state.login.token);

  function handleOpenEditor() {
    setShowModal(true);
  }

  async function showSocialLink() {
    setSocialMedia({ ...link, is_visible: true });
    const response = await toggleVisibilitySocialLink(token, link, true);
    if (!response.ok) window.alert("Error actualizando visibilidad");
  }

  async function hideSocialLink() {
    setSocialMedia({ ...link, is_visible: false });
    const response = await toggleVisibilitySocialLink(token, link, false);
    if (!response.ok) window.alert("Error actualizando visibilidad");
  }

  function renderEditorByType(type) {
    if (type === "image") {
      return (
        <EditImageTypeModal
          showModal={showModal}
          setSocialMedia={setSocialMedia}
          deleteSocialMedia={deleteSocialMedia}
          setShowModal={setShowModal}
          link={link}
        />
      );
    }

    return (
      <EditSocialLinkModal
        showModal={showModal}
        setSocialMedia={setSocialMedia}
        deleteSocialMedia={deleteSocialMedia}
        setShowModal={setShowModal}
        link={link}
      />
    );
  }
  return (
    <div
      key={index}
      className="px-4 py-3 relative rounded-md flex justify-between bg-stone-100"
    >
      <div className="flex items-center gap-4">
        <div className="h-[2.8rem] text-xs flex items-center justify-center">
          <img
            className="max-w-[2.8rem] aspect-square object-cover rounded-xl"
            src={overrideUndefinedImage(
              link.type,
              `${IMAGE_DOMAIN}${link.image}`
            )}
            alt={`Icono de ${link.title}`}
          />
        </div>
        <p className="text-sm break-all">{link.title}</p>
      </div>

      <div className="flex items-center w-fit">
        <div className="mr-4 flex justify-center text-stone-700 hover:text-stone-500">
          {link.is_visible ? (
            <button onClick={hideSocialLink}>
              <FaEye className="cursor-pointer" size={23} />
            </button>
          ) : (
            <button onClick={showSocialLink}>
              <FaEyeSlash className="cursor-pointer" size={23} />
            </button>
          )}
        </div>
        <button onClick={handleOpenEditor}>
          <FaPencilAlt
            className="text-stone-700 cursor-pointer hover:text-stone-500"
            size={20}
          />
        </button>
      </div>
      {showModal && renderEditorByType(link.type)}
    </div>
  );
};
export default EditSocialLinkButton;
