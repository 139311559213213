import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { FiShare } from "react-icons/fi";
import LoadingOption from "../../components/LoadingOption";

import { DOMAIN, IMAGE_DOMAIN } from "../../config/Constant";
import EditSocialLinkButton from "./EditCardComponents/EditSocialLinkButton";
import AddSocialMediaButton from "./EditCardComponents/AddSocialMediaButton";
import PreviewDigitalCard from "./PreviewDigitalCard";
import CardContainer from "../../components/CardContainer";

import { useForm } from "react-hook-form";

import AddCustomLinkButton from "./EditCardComponents/AddCustomLinkButton";
import EditAvatarButton from "./EditCardComponents/EditAvatarButton";
import EditBackgroundButton from "./EditCardComponents/EditBackgroundButton";
import EditColorButton from "./EditCardComponents/EditColorToolbar";
import AddWhatsappModal from "./EditCardComponents/ModalBase/AddWhatsappModal";
import AddEmailModal from "./EditCardComponents/ModalBase/AddEmailModal";
import AddPhoneModal from "./EditCardComponents/ModalBase/AddPhoneModal";
import AddMapsModal from "./EditCardComponents/ModalBase/AddMapsModal";
import AddContactModal from "./EditCardComponents/ModalBase/AddContactModal";
import AddStoreModal from "./EditCardComponents/ModalBase/AddStoreModal";
import { AddSocialMediaButtonTemplate } from "../admin/Components/MultipleCardEditionModal";
import { createCustomSocialMedia, updateAvatarPhoto, updateProfile } from "../admin/Api/UserServices";
import AddImageModal from "./EditCardComponents/ModalBase/AddImageModal";
import { LinkCreatorOptions } from "./EditCardComponents/LinkCreationOptions";
import { RadioGroup, RadioGroupIndicator, RadioGroupItem } from "@radix-ui/react-radio-group";
import { toast} from 'react-toastify'

async function fetchData(endpoint, token) {
  const response = await fetch(DOMAIN + endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    
  });
  return response;
}


function EditCard(props) {
  const token = useSelector((state) => state.login.token);
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [customSocialMedia, setCustomSocialMedia] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const { userData, userPersonified } = props;

  const [openPreview, setOpenPreview] = useState(false);

  let id = userData.username;
  if (userPersonified) {
    id = userPersonified.username;
  }

  const getProfile = async () => {
    const response = await fetchData(`profile/userWithoutStatcis/${id}`, token);
    
    const profile = await response.json();

    if (!response.ok) throw Error(response);
    return profile;
  };

  const loadProfileData = () => {
    getProfile()
      .then((response) => {
        setProfileData(response.data.profile);
        setCustomSocialMedia(response.data.custom_social_media);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(`Ocurrió un error al obtener los datos. Status: ${error.status}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadedSuccessfully() {
    return errorMessage.length === 0;
  }

  function handleOpenPreview() {
    setOpenPreview(!openPreview);
  }

  return (
    <div className="w-full font-montserrat bg-almost-white">
      {isLoading ? (
        <div className="w-full h-screen">
          <LoadingOption />
        </div>
      ) : (
        <>
          {loadedSuccessfully() ? (
            <div className="flex flex-col">
              <div className="mt-8 px-8 flex justify-between">
                <h2 className="font-medium text-lg min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
                  Datos de tu mascota
                </h2>
              </div>

              <EditCardBody
                setCustomSocialMedia={setCustomSocialMedia}
                customSocialMedia={customSocialMedia}
                setProfileData={setProfileData}
                profileData={profileData}
                openPreview={openPreview}
                userData={userData}
              />
            </div>
          ) : (
            <>
              <div>
                <p> Error: </p>
                <p> {errorMessage} </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export function EditCardBody(props) {
  const { userData, profileData, customSocialMedia, openPreview } = props;
  const { setProfileData, setCustomSocialMedia } = props;

  let { onSubmitAboutMe, onSocialMediaCreateForAll, editAvatarOrBackground } = props;

  onSocialMediaCreateForAll = onSocialMediaCreateForAll ?? createCustomSocialMedia;

  const token = useSelector((state) => state.login.token);

  const [localGenero, setLocalGenero] = useState(profileData.pet_gender || ''); 
  const [localStatus, setLocalStatus] = useState(profileData.pet_status || ''); 


  const { register, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues: profileData,
  });

  function handleReloadFormData(newProfileData) {
    reset({ ...(newProfileData ?? profileData) });
  }

  function handleUpdatePreviewText(event, propertyName) {
    const value = event.target.value;
    const object = { [propertyName]: value };
    setProfileData({
      ...profileData,
      ...object,
    });
  }

  function handleUpdateColorUI(event, color) {
    let value = "";
    if (event !== undefined) {
      value = event.target.value;
    } else {
      value = color;
      setValue("color", value, { shouldDirty: true });
    }
    const object = { color: value };

    setProfileData({
      ...profileData,
      ...object,
    });
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  function handleSubmitAboutMe(data) {
    const { pet_name, career, description, color, pet_breed, genero, pet_type, fecha_nacimiento, estado_mascota, pet_illness, pet_vet } = data;

    const dataUser = {
      user_id: userData?.id,
      pet_name: pet_name,
      career: career,
      description: description,
      color: color,
      pet_breed: pet_breed,
      pet_gender :genero,
      pet_type:pet_type,
      pet_birthdate: fecha_nacimiento,
      pet_status : estado_mascota, 
      pet_illness:pet_illness,
      pet_vet : pet_vet
    };
    let validOnSubmit = onSubmitAboutMe ?? updateProfile;

    validOnSubmit(dataUser, token)
      .then((req) => {
        handleReloadFormData(data);
        toast.success("perfil actualizado!", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        window.alert("Error al actualizar información de la tarjeta");
      });
  }

  function setCustomSocialMediaIndexUI(index) {
    return (socialMedia) => {
      customSocialMedia[index] = socialMedia;
      setCustomSocialMedia([...customSocialMedia]);
    };
  }

  function deleteCustomSocialMediaIndexUI(index) {
    return () => {
      customSocialMedia.splice(index, 1);
      setCustomSocialMedia([...customSocialMedia]);
    };
  }

  function addCustomSocialMediaUI(link) {
    setCustomSocialMedia([...customSocialMedia, link]);
  }

  return (
    <div className="mt-4 flex">
      <div className="flex flex-col flex-1  px-6  sm:pr-6 gap-6">
        <CardContainer className="px-6 pb-6 text-sm bg-white rounded-md shadow-md">
          <form className="grid grid-cols-1 gap-4 lg:grid-cols-2" onSubmit={handleSubmit(handleSubmitAboutMe)}>
            <div className="flex flex-col">
              <label className="tmt-2 text-stone-800 font-medium" htmlFor="pet_name">
                Nombre de tu mascota
              </label>
              <input
                className="mt-1 px-4 py-2 border outline-none w-full"
                id="pet_name"
                type="text"
                {...register("pet_name")}
                placeholder="Nombre"
                required
              />
              <label className="mt-2 text-stone-800 font-medium" htmlFor="pet_type">
                Especie
              </label>
              <input
                className="mt-1 px-4 py-2 border outline-none w-full"
                id="pet_type"
                type="text"
                {...register("pet_type")}
                placeholder="Especie"
                required
              />

              <label className="mt-2 text-stone-800 font-medium" htmlFor="pet_breed">
                Raza
              </label>
              <input
                className="mt-1 px-4 py-2 border outline-none w-full"
                id="pet_breed"
                type="text"
                {...register("pet_breed")}
                placeholder="Raza"
                required
              />

              <label className="mt-2 text-stone-800 font-medium"
                htmlFor="career"
              >
                Familia / Propietario:
              </label>
              <input
                className="mt-1 px-4 py-2 border outline-none w-full"
                id="career"
                type="text"
                {...register("career")}
                placeholder="Familia"
                required
              />

              <label
                className="mt-2 text-stone-800 font-medium"
                htmlFor="description"
              >
                Descripción
              </label>
              <textarea
                className="px-4 py-2 border outline-none w-full resize-none"
                {...register("description", {
                  onChange: (event) =>
                    (event, "description"),
                })}
                name="description"
                id="description"
                rows={2}
                maxLength={75}
                placeholder="Descripción de tí"
              ></textarea>
            </div>

            <div className="flex flex-col">
              <label className="mt-2 lg:mt-0 text-stone-800 font-medium" htmlFor="raza">
                Género
              </label>
              <div className="mt-1 gap-x-4 flex w-full">
                <label className="relative py-[19px] flex-1">
                  <input className="peer hidden" type="radio" value="M" {...register("genero")}
                  checked={localGenero === "M"}
                  onChange={() => {
                    setValue("genero", "M", { shouldDirty: true });
                    setLocalGenero("M");
                  }}  />

                  <div className="inset-0 absolute peer-checked:bg-blue-400 peer-checked:border-blue-400 border-stone-200 border-2"></div>
                  <label className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  text-stone-400 peer-checked:text-white">Macho</label>
                </label>
                <label className="relative py-[19px] flex-1">
                  <input className="peer hidden" type="radio" value="F" {...register("genero")} 
                  checked={localGenero === "F"}
                  onChange={() => {
                    setValue("genero", "F", { shouldDirty: true });
                    setLocalGenero("F");
                  }}/>
                  <div className="inset-0 absolute peer-checked:bg-pink-400 peer-checked:border-pink-400 border-stone-200 border-2"></div>
                  <label className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  text-stone-400 peer-checked:text-white">Hembra</label>
                </label>
              </div>

              <label className="mt-2 text-stone-800 font-medium">Fecha de nacimiento: </label>
              <input
                className="mt-1 px-4 py-2 border outline-none w-full invalid: text-stone-600"
                type="date"
                placeholder="Seleccionar fecha"
                {...register("fecha_nacimiento")}
                defaultValue={profileData.pet_birthdate}
              />

              <label className="mt-2 text-stone-800 font-medium" htmlFor="estado_mascota">
                Estado
              </label>
              <div className="mt-1 gap-x-4 text-sm font-bold flex w-full">
                <label className="relative py-[19px] flex-1 first:checked:bg-stone-400">
                  <input className="peer hidden" type="radio" value="Casa" {...register("estado_mascota")}
                    checked={localStatus === "Casa"}
                    onChange={() => {
                      setValue("estado_mascota", "Casa", { shouldDirty: true });
                      setLocalStatus("Casa");
                    }}/>
                  <div className="inset-0 absolute peer-checked:bg-green-500 peer-checked:border-green-600 border-stone-200 border-2"></div>
                  <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  text-stone-400 peer-checked:text-white">
                    En casa
                  </p>
                </label>
                <label className="relative py-[19px] flex-1 first:checked:bg-stone-400">
                  <input className="peer hidden" type="radio" value="Perdido" {...register("estado_mascota")}
                    checked={localStatus === "Perdido"}
                    onChange={() => {
                      setValue("estado_mascota", "Perdido", { shouldDirty: true });
                      setLocalStatus("Perdido");
                    }}/>
                  <div className="inset-0 absolute peer-checked:bg-red-500 peer-checked:border-red-600 border-stone-200 border-2"></div>
                  <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  text-stone-400 peer-checked:text-white">
                    Perdido
                  </p>
                </label>
              </div>
            </div>

            <div className="flex flex-col">

              

              


            {/* Botón de Actualizar */}
            <button
              type="submit"
              disabled={!formHasBeenEdited()}
              className="mt-6 px-8 py-2 w-full max-w-xs font-medium bg-orange-500 text-white rounded-md disabled:bg-stone-300 disabled:text-stone-700"
            >
              Actualizar
            </button>
            </div>

            


          </form>
        </CardContainer>

        
        <CardContainer className="px-6 pt-4 pb-6 text-sm bg-white rounded-md shadow-md flex">
  <div className="mr-4">
    <label className="font-medium">Foto de tu mascota</label>
    <div className="mt-1 flex flex-col gap-4 sm:gap-16 sm:flex-row">
      <EditAvatarButton
        profileData={profileData}
        setProfileData={setProfileData}
        image={`${IMAGE_DOMAIN}${profileData.image}`}
        editAvatarOrBackground={editAvatarOrBackground}
      />
    </div>
  </div>
  <div>
    <label className="font-medium">Elige un Fondo</label>
    <div className="mt-1 flex flex-col gap-4 sm:gap-16 sm:flex-row">
      <EditBackgroundButton
        profileData={profileData}
        setProfileData={setProfileData}
        image={`${IMAGE_DOMAIN}${profileData.background}`}
        editAvatarOrBackground={editAvatarOrBackground}
      />
    </div>
  </div>
</CardContainer>

        <CardContainer className="px-6 pt-4 pb-6 text-sm bg-white rounded-md shadow-md">
          <h3 className="font-medium"> Datos adicionales </h3>
          <form className="flex flex-col" onSubmit={handleSubmit(handleSubmitAboutMe)}>
            <label className="mt-2 text-stone-800 font-medium" htmlFor="pet_illness">
              Enfermedades
            </label>
            <input
              className="mt-1 px-4 py-2 border outline-none w-full"
              id="pet_illness"
              type="text"
              {...register("pet_illness")}
              placeholder="Enfermedades"
              required
            />

            <label className="mt-2 text-stone-800 font-medium" htmlFor="pet_vet">
              Veterinaria asociada
            </label>
            <input
              className="mt-1 px-4 py-2 border outline-none w-full"
              id="pet_vet"
              type="text"
              {...register("pet_vet")}
              placeholder="Nombre de veterinaria"
              required
            />
          </form>
        </CardContainer>
        <CardContainer className="mb-6 px-6 py-4 shadow-md">
          <p className="font-medium"> Formas de contacto </p>
          <div className="mt-4 gap-3 flex flex-col">
            {customSocialMedia.map((link, index) => {
              return (
                <EditSocialLinkButton
                  key={index}
                  index={index}
                  link={link}
                  deleteSocialMedia={deleteCustomSocialMediaIndexUI(index)}
                  setSocialMedia={setCustomSocialMediaIndexUI(index)}
                />
              );
            })}
          </div>
          <AddCustomLinkButton
            onSubmit={(data, token) => {
              data.user_id = profileData.customer_user;
              return onSocialMediaCreateForAll(data, token);
            }}
            addSocialMedia={addCustomSocialMediaUI}
          />
          <LinkCreatorOptions
            onCompleteForAll={addCustomSocialMediaUI}
            onSubmitForAll={(data, token) => {
              data.user_id = profileData.customer_user;
              return onSocialMediaCreateForAll(data, token);
            }}
          />
        </CardContainer>
      </div>
    </div>
  );
}

export default EditCard;

function filterVisibleSocialMedia(links) {
  return links.filter((link) => link.is_visible === true);
}
