import React, { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import {createDiscount, updateDiscount} from '../Api/discount.api'
import {getAllCompanies} from '../Api/superUserService'
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const copyToClipboard = (text) => {
  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = text;

  document.body.appendChild(tempTextArea);
  tempTextArea.select();
  document.execCommand("copy");

  document.body.removeChild(tempTextArea);

  // Mostrar el toast de éxito
  toast.success("¡Código copiado al portapapeles!", {
    position: "top-center",
    autoClose: 2000, // Duración del toast en milisegundos
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    // Puedes personalizar más opciones según tus necesidades
  });
};

const Modal = ({ isOpen, onClose, children }) => {
  const handleContainerClick = (event) => {
    // Si el clic no es descendiente del modal, cierra el modal
    if (!event.target.closest('.modal-content')) {
      onClose();
    }
  };

  return (
    
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    onClick={handleContainerClick}>
    <div className="relative max-w-md mx-auto my-6">
      <div className="border-0 rounded-lg shadow-lg bg-white">
        <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
          <h3 className="text-xl font-semibold">Descuentos</h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-xl leading-none font-semibold outline-none focus:outline-none"
            onClick={onClose}
          >
            <span className="text-black h-6 w-6 text-lg block outline-none focus:outline-none">×</span>
          </button>
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  </div>
  );
};

const CreateDiscountModal = ({isOpen, onClose, discount, userId, username}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [verificationCode, setVerificationCode]=useState('');
  const [storedVerificationCode, setStoredVerificationCode] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    submit,
  } = useForm();
  
  const token = useSelector((state) => state.login.token);

  const onSubmit = handleSubmit(async (data) => {
    console.log('Datos del formulario:', data);
    try {
      // Llama a la función para crear un nuevo descuento
      const res = await createDiscount(data, token);
      toast.success("Descuento creado", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      reset();
    
    closeModalHandler();
       
    } catch (error) {
      console.error('Error al manejar el descuento:', error.message);
      if (error.response && error.response.data) {
        console.log('Detalles del error:', error.response.data);
      }
    }
  });

  const [selectedDiscountType, setSelectedDiscountType]=useState('p');

  const isDateBeforeToday = (date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Restar un día a la fecha actual
  
    const inputDate = new Date(date);
    
    // Comparar solo las fechas (sin horas, minutos, segundos, milisegundos)
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
  
    return inputDate >= currentDate;
  };

  const isDateAfterInitialDate = (finalDate, initialDate) => {
    const endDate = new Date(finalDate);
    const startDate = new Date(initialDate);

    return endDate >= startDate;
  };

  useEffect(() => {
    if (discount) {
      setValue('customer_user', discount.customer_user);
      setValue('social_media', discount.social_media);
      setValue('discount_type', discount.discount_type);
      setValue('discount_rate', discount.discount_rate);
      setValue('initial_date', discount.initial_date);
      setValue('final_date', discount.final_date);
      setValue('verification_code', discount.verification_code);
      // Almacena el valor actual del código de verificación
      setStoredVerificationCode(discount.verification_code);
      setValue('status', discount.status);
      // ... otros campos según sea necesario
    }
  }, [discount, setValue]);

  const generateVerificationCode = () => {
    // Solo genera el código de verificación si no estás en modo de edición
    const customer_user = document.getElementById('username-display').textContent.substring(0, 4);
    const socialMedia = watch('social_media');
    const discountType = watch('discount_type');
    const discountRate = watch('discount_rate');
    const initialDate = watch('initial_date');
    // Obtener mes y año de initial_date
    const initialDateObject = new Date(initialDate);
    const month = initialDateObject.getMonth() + 1; // Sumar 1 ya que los meses comienzan desde 0
    const year = initialDateObject.getFullYear();
    // Obtener el símbolo correspondiente a discountType
    const symbol = discountType === 'percentage' ? 'p' : '$';
    // Formar el código de verificación
    const newVerificationCode = `${customer_user}-${socialMedia}-${symbol}-${discountRate}-${month}-${year}`;
    // Actualizar el valor del campo verification_code en el formulario
    setValue('verification_code', newVerificationCode);
  };

  return (
    <div className="">
      <button
        className="mr-2 text-blue-600 hover:underline"
        onClick={openModal}
      >
        {discount ? 'Editar' : 'Crear Descuento'}
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModalHandler}> 
      <form onSubmit={onSubmit} className="max-w-xl mx-auto bg-white p-8 mt-10 rounded shadow-md grid grid-cols-2 gap-8">

  {/* customer_user */}
  <div className="mb-4">
    <label htmlFor='customer_user' className="block text-sm font-medium text-gray-600">
      Usuario
    </label>
    <p id='username-display'> {username}</p>
    <input
      type='text'
      value={userId}
      readOnly
      onChange={(e) => setInputValue(e.target.value)}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      {...register("customer_user", {
        required: true,
        maxLength: {
          value: 9,
          message: "Sólo debe ingresarse nombre de usuario"
        }
      })}
    />
    {errors.customer_user && <span className="text-red-500">{errors.customer_user.message}</span>}
  </div>

  {/* social_media */}
  <div className="mb-4">
    <label htmlFor='social_media' className="block text-sm font-medium text-gray-600">
      Red Social
    </label>
    <select
      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
      {...register("social_media", {
        required: true
      })}
       
    >
      <option value="fb">Facebook</option>
      <option value="ig">Instagram</option>
      <option value="yt">Youtube</option>
      <option value="tw">Twitter</option>
      <option value="lk">Linkedin</option>
    </select>
    {errors.social_media && <span className="text-red-500">Debe elegir entre las opciones</span>}
  </div>

  {/* discount_type */}
  <div className="mb-4">
    <label htmlFor='discount_type' className="block text-sm font-medium text-gray-600">
      Tipo de Descuento
    </label>
    <select
      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
      {...register("discount_type", {
        required: true
      })}
      onChange={(e) => setSelectedDiscountType(e.target.value)}
      
    >
      <option value={"percentage"}>Porcentual (%)</option>
      <option value={"price"}>Monetario (Bs)</option>
    </select>
    {errors.discount_type && <span className="text-red-500">Debe elegir entre las opciones</span>}
  </div>

  {/* discount_rate */}
  <div className="mb-4">
    <label htmlFor='discount_rate' className="block text-sm font-medium text-gray-600">
      Monto de Descuento
    </label>
    <div className='relative'>
      <input
        type='text'
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        {...register("discount_rate", {
          required: true,
          pattern: {
            value: /^\d+(\.\d{1,2})?$/,
            message: "El valor debe ser numérico"
          },
          validate: (value) => {
            if (selectedDiscountType === 'p' && parseInt(value, 10) > 20) {
              return "El monto de descuento no debe superar el 20%";
            }
            if (selectedDiscountType === 'Bs' && parseInt(value, 10) > 80) {
              return "El monto de descuento no debe superar los 80 Bs";
            }
            return true; // La validación pasa
          },
        })}
        placeholder={` - `}
      />

      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span id='selectedDiscountType' className="text-gray-500">{selectedDiscountType === 'percentage' ? 'p' : 'Bs.'}</span>
      </div>
    </div>
    {errors.discount_rate && <span className="text-red-500">{errors.discount_rate.message}</span>}
  </div>

  {/* initial_date */}
  <div className="mb-4">
    <label htmlFor='initial_date' className="block text-sm font-medium text-gray-600">
      Fecha Inicial
    </label>
    <input
      type='date'
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      {...register("initial_date", {
        required: "Fecha fuera del límite",
        validate: (value) => {
          // Validar que la fecha sea igual o posterior al día actual
          return isDateBeforeToday(value) || "La fecha inicial debe ser igual o posterior al día actual";
        },
      })}
    />
    {(!discount && errors.initial_date) && (
      <span className="text-red-500">{errors.initial_date.message}</span>
    )}
  </div>

  {/* final_date */}
  <div className="mb-4">
    <label htmlFor='final_date' className="block text-sm font-medium text-gray-600">
      Fecha Final
    </label>
    <input
      type='date'
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      {...register("final_date", {
        required: "Fecha fuera del límite",
        validate: (value) => {
          const initialDate = watch('initial_date'); // Obtén el valor de initial_date
          return isDateAfterInitialDate(value, initialDate) || "La fecha final debe ser posterior a la fecha inicial";
        },
      })}
    />
    {errors.final_date && <span className="text-red-500">{errors.final_date.message}</span>}
  </div>

  {/* verification_code */}
  <div className="mb-4">
  
    <label htmlFor='verification_code' className="block text-sm font-medium text-gray-600">
      Código de Verificación
    </label>
    
    <button
      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      type="button"
      onClick={generateVerificationCode}
    >
      Generar
    </button>
 
    <input
      type='text'
      className="shadow appearance-none border rounded w-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline verification-code-input"
      value={verificationCode}
      readOnly
      {...register("verification_code", {
        required: true
      })}
    />
    {errors.verificationCode && <span className="text-red-500">Código no generado</span>}
    {/* Nuevo elemento para mostrar el valor durante la edición */}
  {discount && ( 
    <div className="mt-2 flex items-center">
      <p className="text-gray-600 mr-2">
     {discount.verification_code}
      </p>
    <a
     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
     onClick={() => copyToClipboard("https://www.soyyo.digital/#/buy?id=1&cupon="+discount.verification_code)}
   >
     Copiar
   </a>
 </div>
  )}
  </div>
<br></br>
  {/* status */}
  <div className="mb-4">
    <label htmlFor='status' className="flex items-center">
      <input
        type='checkbox'
        className="form-checkbox h-5 w-5 text-blue-500 focus:outline-none focus:shadow-outline mr-2"
        defaultChecked={true}
        {...register("status")}
      />
      <label className="text-sm">Activo</label>
    </label>
  </div>
  
  <button
  type="submit"
  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
>
  {'Crear Cupón'}
</button>

</form>
      </Modal>
    </div>
  );
};

export  {CreateDiscountModal};