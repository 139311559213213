import axios from 'axios'
import { DOMAIN } from "../../../config/Constant";

export const getAllPets = (token) => {
    return axios.get(`${DOMAIN}allpets/pets/pet/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });
  };

  export const createPet = (token, petData) => {
    return axios.post(`${DOMAIN}allpets/pets/pet/`, petData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });
  };














