import {  useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../firebase/FirebaseUtils";
import { userLogin } from "../redux/loginSlice";

const TokenRefresher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let user = auth.currentUser;
      if(!user) return;
      let token = await user.getIdToken(true);
      dispatch(userLogin({ token }));
    }, //1000 * 60 * 30); // Actualiza token cada 30 min
    1000*60*30);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  return null;
};

export function useTokenRefresher() {
  const dispatch = useDispatch();

  const refreshToken = async () => {
    try {
      let user = auth.currentUser;
      let token = await user.getIdToken(true);
      dispatch(userLogin({ token }));
    } catch (error) {
      console.log(error);
    }
  };

  return refreshToken;
}

export default TokenRefresher;
