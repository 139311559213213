import React, { useState, useEffect } from 'react';
import { getAllPets } from '../Api/petServices';
import { useSelector } from "react-redux";

export function PetsTable({ searchTerm }) {
    const initialToken = useSelector((state) => state.login.token);
    const [token, setToken] = useState(initialToken);
    const [pets, setPets] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (petId) => {
        setExpandedRows((prevRows) => {
          if (prevRows.includes(petId)) {
            return [];
          } else {
            return [petId];
          }
        });
      };

  useEffect(() => {
    async function loadPets() {
      try {
        // Verificar si hay un token antes de realizar la solicitud
        if (!token) {
          console.error('Token no disponible.');
          return;
        }

        const res = await getAllPets(token);
        setPets(res.data);

        const filteredPets = res.data.filter((pet) =>
          Object.values(pet).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
        setPets(filteredPets);
      } catch (error) {
        console.error('Error al cargar mascotas:', error.message);
      }
    }
    loadPets();
  }, [token, searchTerm]);

  return (
    <div className="overflow-x-auto">
        <h2 className="text-2xl font-bold mb-4">Lista de Mascotas</h2>
        
        <div className="container mx-auto mt-8 p-6 bg-white rounded-md shadow-md">  
            {pets.length > 0 ? (
                <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-300">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">ID</th>
                            <th className="py-2 px-4 border-b">Nombre</th>
                            <th className="py-2 px-4 border-b">Tipo</th>
                            <th className="py-2 px-4 border-b">género</th>
                            <th className="py-2 px-4 border-b">Fecha de Nacimiento</th>
                            <th className="py-2 px-4 border-b">Veterinaria</th>
                            <th className="py-2 px-4 border-b">Id en Sistema</th>
                            <th className="py-2 px-4 border-b">Acciones</th>
                            {/* Agrega más encabezados de columna según tus necesidades */}
                        </tr>
                    </thead>
                    <tbody>
                    {pets.map(pet => (
                         <React.Fragment key={pet.id}>
                        <tr key={pet.id} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border-b">{pet.id}</td>
                            <td className="py-2 px-4 border-b">{pet.name}</td>
                            <td className="py-2 px-4 border-b">{pet.pet_type}</td>
                            <td className="py-2 px-4 border-b">{pet.gender}</td>
                            <td className="py-2 px-4 border-b">{pet.birthdate}</td>
                            <td className="py-2 px-4 border-b">{pet.vet}</td>
                            <td className="py-2 px-4 border-b">{pet.customer_user_profile}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="text-blue-600 hover:underline"
                                    onClick={() => toggleRow(pet.id)}
                                >
                                    Ver Detalles
                                </button>
                            </td>
                        </tr>
                        {expandedRows.includes(pet.id) && (
                        <tr>
                        <td colSpan="8" className="py-2 px-4 border-b">
                            {/* Contenido adicional para mostrar cuando se expande */}
                            {/* Puedes agregar aquí la información que deseas mostrar */}
                            <p>Detalles adicionales: {pet.additionalDetails}</p>
                        </td>
                        </tr>
                    )}
                    </React.Fragment>

                    ))}
                    </tbody>
                </table>

                
                </div>
            ) : (
            <p className="text-gray-500">No existen mascotas registradas.</p>
            )}
        </div>
    </div>

  );
}
