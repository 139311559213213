import axios from "axios";
import { DOMAIN } from "../../../config/Constant";

function buildQueryOrNone(name, value) {
  if (value) {
    return `${name}=${value}`;
  }
  return "";
}

export const getAllDiscounts =()=>{
  return axios.get(`${DOMAIN}`)
}


export async function getAllEmployees(token, id = undefined) {
  const response = await fetch(
    `${DOMAIN}adm/licenciaadm${id ? `/${id}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (!response.ok) throw Error("Error al cargar integrantes de empresa");

  return response.json();
}

export async function blockEditionUser(data, token) {
  const response = await fetch(`${DOMAIN}adm/edit/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function editAreaForMany(data, token) {
  const response = await fetch(`${DOMAIN}auth/rubro`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function createCustomSocialMediaForMany(data, token) {
  // TODO: Esto no parece correcto pero no se me ocurren mejores formas
  if (!data.type) {
    console.warn("Usando título como type, implementación incorrecta")
    data.type = data.title.toLowerCase();
  }

  const form = new FormData();
  form.append("title", data.title);
  form.append("url", data.url);
  form.append("is_visible", true);
  form.append("type", data.type);
  form.append("ids", JSON.stringify(data.ids));

  if(data.image) {
    form.append("image", data.image);
  }
  if(data.imageQR) {
    form.append("imageQR", data.imageQR);
  }

  const response = await axios.post(
    DOMAIN + "profile/custom_social_media_for_all_user",
    form,
    {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (response.status !== 200)
    throw Error("Ocurrió un error al crear el enlace");
  return response.data;
}

export async function editProfileForUser(data, token) {
  const form = new FormData();
  for (var key in data) {
    form.append(key, data[key]);
  }

  const user_id = data.id??data.user_id;
  const response = await axios.put(
    `${DOMAIN}profile/user?user_id=${user_id}`,
    form,
    {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (response.status !== 200) throw Error("Error editando perfil");

  return response.data;
}

// TODO: Mover archivo a superUserService.js
export const LicenseTypes = {
  1: "Activa",
  2: "Vencida",
  3: "Bloqueada",
  4: "Ilimitada",
};

export const LicenseEnum = {
  ACTIVA: 1,
  VENCIDA: 2,
  BLOQUEADA: 3,
  ILIMITADA: 4,
};

export async function extendLicense(data, token) {
  data.finalLicenseDate = new Date(data.finalLicenseDate);
  data.startLicenseDate = new Date(data.startLicenseDate);

  const diff =
    data.finalLicenseDate.getTime() - data.startLicenseDate.getTime();

  const diffCompleted = data.finalLicenseDate.getTime() - new Date().getTime();
  let status = LicenseEnum.ACTIVA;
  if (diffCompleted <= 0) {
    status = LicenseEnum.VENCIDA;
  }
  data = {
    id: data.id,
    duracion: diff / (1000 * 60 * 60 * 24),
    status: status,
  };

  const licenseId = data.id;

  const response = await fetch(`${DOMAIN}adm/licenciasup/${licenseId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function getLicense(userId, token) {
  const extra = buildQueryOrNone("user_id", userId)
  const response = await fetch(`${DOMAIN}adm/licencia?${extra}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  return response.json();
}

export async function updateUserDataAdmin(data, token) {
  const response = await fetch(`${DOMAIN}auth/edit-user/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({
      username: data.username,
      phone_number: data.phone_number,
    }),
  });

  if (!response.ok) {
    throw Error((await response.json()).username);
  }

  return response;
}

export async function loadAdminStatistics(token) {
  const response = await fetch(`${DOMAIN}profile/statisticsAdm`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  return response.json();
}

export async function sendExtendLicenseEmail(data, token) {
  const body = {
    type: 1,
    data: data,
  };
  const response = await fetch(`${DOMAIN}email/send-basic-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok)
    throw Error(
      "Ocurrió un problema inesperado, vuelve a intentarlo más tarde"
    );

  return response.json();
}

export async function sendSupportEmail(data, token) {
  const body = {
    type: 0,
    data: data,
  };

  const response = await fetch(`${DOMAIN}email/send-basic-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok)
    throw Error(
      "Ocurrió un problema inesperado, vuelve a intentarlo más tarde"
    );

  return response.json();
}
