import { AddSocialMediaButtonTemplate } from "../../admin/Components/MultipleCardEditionModal";
import AddSocialMediaButton from "./AddSocialMediaButton";
import AddEmailModal from "./ModalBase/AddEmailModal";
import AddImageModal from "./ModalBase/AddImageModal";
import AddPhoneModal from "./ModalBase/AddPhoneModal";
import AddWhatsappModal from "./ModalBase/AddWhatsappModal";

import facebookIcon from "../../../assets/images/social_media_icons/icons8-facebook-96.png";
import instagramIcon from "../../../assets/images/social_media_icons/icons8-instagram-96.png";
import linkedinIcon from "../../../assets/images/social_media_icons/icons8-linkedin-96.png";
import tiktokIcon from "../../../assets/images/social_media_icons/icons8-tiktok-96.png";
import twitchIcon from "../../../assets/images/social_media_icons/icons8-twitch-96.png";
import twitterIcon from "../../../assets/images/social_media_icons/icons8-twitter-96.png";
import mailIcon from "../../../assets/images/social_media_icons/icons8-mail-96.png";
import phoneIcon from "../../../assets/images/social_media_icons/icons8-phone-96.png";
import whatsappIcon from "../../../assets/images/social_media_icons/icons8-whatsapp-96.png";
import googleMapsIcon from "../../../assets/images/social_media_icons/icons8-google-maps-old-96.png";
import imageIcon from "../../../assets/images/social_media_icons/icons8-image-96.png";
import storeIcon from "../../../assets/images/social_media_icons/icons8-store-96.png";
import discountIcon from "../../../assets/images/social_media_icons/icons8-discount-96.png";

import githubIcon from "../../../assets/images/social_media_icons/icons8-github-96.png";
import gitlabIcon from "../../../assets/images/social_media_icons/icons8-gitlab-96.png";
import snapchatIcon from "../../../assets/images/social_media_icons/icons8-snapchat-96.png";
import spotifyIcon from "../../../assets/images/social_media_icons/icons8-spotify-96.png";
import telegramIcon from "../../../assets/images/social_media_icons/icons8-telegram-96.png";
import youtubeIcon from "../../../assets/images/social_media_icons/icons8-youtube-96.png";
import infoIcon from "../../../assets/images/social_media_icons/icons8-info-96.png";
import AddMapsModal from "./ModalBase/AddMapsModal";
import AddContactModal from "./ModalBase/AddContactModal";
import AddStoreModal from "./ModalBase/AddStoreModal";
import AddDiscountModal from "./ModalBase/AddDiscountModal";
import userEvent from "@testing-library/user-event";


export function LinkCreatorOptions(props) {
    const { onCompleteForAll, onSubmitForAll } = props;
    return (
      <>
        <p className="mt-2 font-medium"> General </p>
        <div className="mt-2 flex flex-wrap gap-4">
          <AddSocialMediaButtonTemplate
            icon={mailIcon}
            title="Email"
            content={
              <AddEmailModal
              type="email"
              onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          />
          <AddSocialMediaButtonTemplate
            icon={phoneIcon}
            title="Teléfono"
            content={
              <AddPhoneModal
                onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          />
          <AddSocialMediaButtonTemplate
            icon={googleMapsIcon}
            title="Ubicación"
            content={
              <AddMapsModal
                onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          />
          {/* <AddSocialMediaButtonTemplate
            icon={infoIcon}
            title="Contacto"
            content={
              <AddContactModal
                onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          />
          <AddSocialMediaButtonTemplate
            icon={storeIcon}
            title="Catálogo"
            content={
              <AddStoreModal
                onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          />
          <AddSocialMediaButtonTemplate
            icon={discountIcon}
            title="Cupón"
            content={
              <AddDiscountModal
                onSubmit={onSubmitForAll}
                onComplete={onCompleteForAll}
              />
            }
          /> */}

        </div>
        <p className="mt-4 font-medium"> Social </p>
        <div className="mt-2 flex flex-wrap gap-4">
          <AddSocialMediaButton
            icon={twitterIcon}
            title="Twitter"
            urlPlaceholder="twitter.com/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={instagramIcon}
            title="Instagram"
            urlPlaceholder="instagram.com/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={facebookIcon}
            title="Facebook"
            urlPlaceholder="facebook.com/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={linkedinIcon}
            title="LinkedIn"
            urlPlaceholder="linkedin.com/in/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={tiktokIcon}
            title="Tiktok"
            urlPlaceholder="tiktok.com/@"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={twitchIcon}
            title="Twitch"
            urlPlaceholder="twitch.tv/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={youtubeIcon}
            title="Youtube"
            urlPlaceholder="youtube.com/@"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={snapchatIcon}
            title="Snapchat"
            urlPlaceholder="snapchat.com/add/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={spotifyIcon}
            title="Spotify"
            urlPlaceholder="open.spotify.com/artist/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
        </div>
        <p className="mt-4 font-medium"> Mensajería </p>
        <div className="mt-2 flex flex-wrap gap-4">
          <AddSocialMediaButtonTemplate
            icon={whatsappIcon}
            title="Whatsapp"
            content={
              <AddWhatsappModal
                onComplete={onCompleteForAll}
                onSubmit={onSubmitForAll}
              />
            }
          />
          <AddSocialMediaButton
            icon={telegramIcon}
            title="Telegram"
            urlPlaceholder="telegram.me/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
        </div>
        <p className="mt-4 font-medium"> Media y archivos </p>
        <div className="mt-2 flex flex-wrap gap-4">
          <AddSocialMediaButtonTemplate
            icon={imageIcon}
            title="Imagen"
            content={
              <AddImageModal
                onComplete={onCompleteForAll}
                onSubmit={onSubmitForAll}
              />
            }
          />
        </div>
        {/* <p className="mt-4 font-medium"> Otros </p>
        <div className="mt-2 flex flex-wrap gap-4">
          <AddSocialMediaButton
            icon={githubIcon}
            title="Github"
            urlPlaceholder="github.com/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
          <AddSocialMediaButton
            icon={gitlabIcon}
            title="GitLab"
            urlPlaceholder="gitlab.com/"
            onSubmit={onSubmitForAll}
            onComplete={onCompleteForAll}
          />
        </div> */}
      </>
    );
  }
  