import React, { useState } from "react";

import TuMascotaLogoColor from "../../../src/assets/images/tumascota-full-color.png";
import TuMascotaHuella from "../../assets/images/logo-tu-mascota-black.png";
import { SupportModal } from "../../components/Modals/SupportModal";

import { 
  FaCog, 
  FaChartBar, 
  FaRegBuilding, 
  FaHeadset,
  FaTicketAlt,
} from "react-icons/fa";
import { RiFileList3Line } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";


export const OptionAdmin = {
  COMPANY: 0,
  ANALYTICS: 1,
  LICENCE: 2,
  SETTINGS: 3,
  DISCOUNT: 4,
};

const SidebarButton = (props) => {
  const { icon, title, selected, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      onClick={handleClick}
      className={` ${
        selected
        ? "font-medium text-white bg-orange-cake"
        : "text-stone-600/95 hover:bg-stone-200/60"
  } mx-0 sm:mx-4 rounded-none sm:rounded-lg flex-1 py-3 sm:py-[10px] sm:px-4 sm:pl-2 hover:cursor-pointer`}
    >
      <div className={`items-center flex flex-col sm:flex-row`}>
        {<div className="sm:ml-2"> {icon} </div>}
        <h2 className="mt-1 text-base sm:mt-0 sm:ml-4 text-ellipsis whitespace-nowrap">
          {title}
        </h2>
      </div>
    </div>
  );
};

export const SidebarAdmin = (props) => {
  const { selected, setSidebarOption } = props;
  const { logout } = props;

  const [showSupportModal, setShowSupportModal] = useState(false);

  const defaultIconSize = 16;
  return (
    <div className="sidebar-shadow z-30 sm:z-[5] inset-0 top-auto shadow-lg fixed text-xl sm:w-56 sm:top-0 sm:right-auto bg-white">
      <div className="sm:flex sm:flex-col sm:h-full sm:justify-between">
        <div className="sm:mt-[8vh]">
          <img
            className="hidden h-16 object-contain mx-auto sm:block"
            alt="tumascota icon"
            src={TuMascotaLogoColor}
          />

          <p className="mt-4 w-fit mx-auto text-sm font-semibold text-stone-800 hidden sm:flex"> Administrador </p>

          <div className="flex flex-1 gap-0 sm:gap-2 sm:flex-none sm:mt-8 sm:flex-col">

            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.COMPANY)}
              selected={selected === OptionAdmin.COMPANY}
              icon={<FaRegBuilding size={defaultIconSize} />}
              title="Mascotas"
            />
            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.ANALYTICS)}
              selected={selected === OptionAdmin.ANALYTICS}
              icon={<FaChartBar size={defaultIconSize} />}
              title="Métricas"
            />
            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.LICENCE)}
              selected={selected === OptionAdmin.LICENCE}
              icon={<RiFileList3Line size={defaultIconSize} />}
              title="Licencia"
            />
            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.SETTINGS)}
              selected={selected === OptionAdmin.SETTINGS}
              icon={<FaCog size={defaultIconSize} />}
              title="Opciones"
            />
            {/* <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.DISCOUNT)}
              selected={selected === OptionAdmin.DISCOUNT}
              icon={<FaTicketAlt size={defaultIconSize} />}
              title="Cupones"
            /> */}
          </div>
        </div>

        <div className="hidden relative sm:block">
          <img
            className="absolute -top-full left-1/2 rotate-12 -translate-x-1/2 opacity-10 w-16"
            alt="_"
            src={TuMascotaHuella}
          ></img>
          <div className="mx-auto mb-4 w-3/4 h-[2px] rounded-md bg-orange-cake"></div>
          <div className="mb-4">
            <SidebarButton
              onClick={() => setShowSupportModal(true)}
              selected={false}
              icon={<FaHeadset size={defaultIconSize} />}
              title="Soporte"
            />
            <SupportModal
              setShowModal={setShowSupportModal}
              showModal={showSupportModal}
            />
            <SidebarButton
              onClick={() => logout()}
              selected={false}
              icon={<FiLogOut size={defaultIconSize} />}
              title="Cerrar sesión"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
