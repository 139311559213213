import React, { useRef, useState } from "react";
import UndefinedImage from "../../../assets/images/social_media_icons/icon-undefined-background.jpg";
import axios from "axios";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { compressImage, uploadImage } from "../../../utils/uploadImages";
import { DOMAIN } from "../../../config/Constant";
import { addHttpsToUrl } from "../../../utils/addHttpsToUrl";
import OverlayModal from "../../../components/Modals/OverlayModal";
import LoadingButton from "../../../components/LoadingButton";

async function createPersonalLink(link, token) {
  // TODO: Refactorizar en una carpeta de /Services o /Repositories

  const linkObject = {
    title: link.title,
    url: addHttpsToUrl(link.url),
    is_visible: true,
    type: link.type,
  };

  const response = await fetch(DOMAIN + "profile/custom_social_media", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(linkObject),
  });

  return response;
}

async function editCustomSocialMediaImage(token, socialMediaId, imageData) {
  // Utilizo axios para enviar el formData
  const url = DOMAIN + "profile/custom_social_media/" + socialMediaId;

  const data = new FormData();
  data.append("image", imageData);
  const response = await axios.put(url, data, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  return response;
}

const AddSocialMediaModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal, titleType, addSocialMedia } = props;
  const { onComplete, onSubmit } = props;

  // Usado por AddSocialMediaButton.js
  const {
    defaultImageSource,
    disableImageUpload,
    titlePlaceholder, // Se usa para determinar el type del enlace, importante no modificar
    urlPlaceholder,
  } = props;

  const [imageSource, setImageSource] = useState(
    defaultImageSource ?? UndefinedImage
  );
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageFormData, setImageFormData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      title: titlePlaceholder ?? "",
      url: urlPlaceholder ?? "",
    },
  });

  const urlDescription = disableImageUpload ? "Usuario" : "Dirección de enlace";

  const fileRef = useRef(null);
  function closeModal() {
    setShowModal(false);
  }

  async function handleImageUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);
      setImageUploaded(true);

      const file = await compressImage(files);
      setImageFormData(file);
    });
  }

  function handleOpenFilePicker() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function linkHasBeenModified() {
    return formState.isDirty;
  }

  async function handleAddCustomSocialMedia(addedLink) {
    if (onSubmit) {
      setIsLoading(true);

      if(!disableImageUpload) {
        addedLink.image = imageFormData;
      }
      addedLink.type = titlePlaceholder?.toLowerCase()
      onSubmit(addedLink, token)
        .then((response) => {
          onComplete(response.data);
          closeModal();
        })
        .catch((error) => {
          window.alert(error);
        }).finally( () => {
          setIsLoading(false);
          closeModal();
        });
      return;
    }
    // TODO: Todo aquí abajo es código muerto y no debería ejecutarse nunca
    console.warn("Dead code - AddSocialMediaModal")
    addedLink.type = titlePlaceholder?.toLowerCase();
    const responsePromise = createPersonalLink(addedLink, token);

    setIsLoading(true);
    responsePromise.then(async (response) => {
      const dataJson = await response.json();
      const linkId = dataJson.data.id;

      if (disableImageUpload) {
        addSocialMedia(dataJson.data);
        closeModal();
        return;
      }
      const imagePromise = editCustomSocialMediaImage(
        token,
        linkId,
        imageFormData
      );
      imagePromise
        .then((responseImg) => {
          addedLink.image = responseImg.data.data.image;

          let linkObject = { ...addedLink, id: linkId, is_visible: true };
          addSocialMedia(linkObject);
          closeModal();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }

  return (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        className={`fixed right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 px-7 pt-4 pb-4 z-20 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        <p className="font-medium"> {`Agregar enlace ${titleType}`} </p>
        <div className="mt-4 flex items-center">
          <button
            className="filter hover:contrast-[0.9]"
            onClick={handleOpenFilePicker}
          >
            {/* TODO: Corregir aspect-ratio de imagen añadida*/}
            <img
              className="max-w-[4rem] rounded-md cursor-pointer"
              alt={`Añadir foto`}
              src={imageSource}
            />
          </button>

          {disableImageUpload ? (
            <></>
          ) : (
            <p
              className="ml-4 text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-600"
              onClick={handleOpenFilePicker}
            >
              Seleccionar imagen ( *png, *jpg, *jpeg )
            </p>
          )}
          <input
            ref={fileRef}
            onChange={handleImageUpload}
            disabled={disableImageUpload}
            className="hidden"
            accept="image/x-png,image/jpeg"
            type="file"
          />
        </div>
        <form
          className="mt-4 flex flex-col text-sm"
          action="POST"
          onSubmit={handleSubmit(handleAddCustomSocialMedia)}
        >
          <label className="mt-0 text-stone-800 font-medium" htmlFor="title">
            Título
          </label>
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="title"
            placeholder="Título"
            {...register("title")}
            required={true}
          />

          <label className="mt-4 text-stone-800 font-medium" htmlFor="career">
            {urlDescription}
          </label>
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="url"
            {...register("url")}
            placeholder="https://website.com"
            required={true}
          />
          <div className="mt-4 flex items-center justify-end">
            <div className="flex sm:flex-row gap-2">
              <button
                type="button"
                onClick={closeModal}
                className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
              >
                Cancelar
              </button>

              <LoadingButton
                type="submit"
                className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                disabled={
                  !linkHasBeenModified() ||
                  !(imageUploaded || disableImageUpload)
                }
                isLoading={isLoading}
              >
                Agregar
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddSocialMediaModal;
