import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { AiOutlineMail, AiOutlineLock, AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import TuMascotaLogo from "../../../assets/images/tumascota-full-color.png";

import firstStep from "../../../assets/landing-page/steps/first_step.jpeg";
import LoadingButton from "../../../components/LoadingButton";

import { ROUTER_INITIAL } from "../../../config/Constant";
import { userLogin } from "../../../redux/loginSlice";
import { objectToUserRole } from "../../../utils/objectToUserRole";
import { loginUserService } from "../../admin/Api/UserServices";
import { PAYMENT_STEPS } from "../PaymentPage";

import "./PaymentPageStyles.css";

export function LoginModal(props) {
  const { setOpenModal, onSuccessfulLogin } = props;
  const { register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  function handleLogin(data) {
    setLoading(true);

    onSuccessfulLogin();
    loginUserService(data)
      .then((response) => {
        const userData = response.data;
        let userRole = objectToUserRole(userData);
        dispatch(userLogin({ token: userData.token, userRole }));
        onSuccessfulLogin();
        close();
      })
      .catch((error) => {
        const firebaseError = error.code;
        if (firebaseError === "auth/user-not-found") {
          console.log("User not found");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function close() {
    setOpenModal(false);
  }

  return (
    <DialogPortal>
      <DialogOverlay className="fixed inset-0 bg-stone-950/25" />
      <DialogContent className="login-content-show max-w-full pb-4 z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-stone-300 shadow-lg fixed rounded-xl bg-white">
        <div className="pt-6 px-7">
          <div className="flex items-center">
            <img alt="Logo" src={TuMascotaLogo} className="h-5 w-5" />
            <h3 className="ml-2 text-xl font-medium">Proceder a la compra</h3>

            <button
              onClick={close}
              aria-label="cerrar modal"
              className="p-1 top-4 right-4 box-border absolute rounded-full text-stone-600 hover:bg-stone-200"
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className="mt-2 flex flex-row">
            <div className="hidden lg:block pl-2 pr-8 py-2">
              <img
                alt="App"
                src={firstStep}
                className="w-64 aspect-[3/5] object-cover rounded-lg"
              />
            </div>
            <div className="w-[20rem] flex flex-col">
              <form
                onSubmit={handleSubmit(handleLogin)}
                className="h-full text-sm flex flex-col justify-between"
              >
                <div className="flex flex-col">
                  <p className="mt-4 font-medium text-lg">
                    Pero antes... inicia sesión o regístrate!
                  </p>
                  <div className="mt-4 flex items-center border-b border-stone-400">
                    <AiOutlineMail className="text-stone-600" size={24} />
                    <input
                      type="email"
                      placeholder="Correo electrónico"
                      className="px-3 py-2 w-full outline-none placeholder:text-stone-600"
                      {...register("email", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="mt-4 flex items-center border-b border-stone-400">
                    <AiOutlineLock className="text-stone-600" size={24} />
                    <input
                      type="password"
                      placeholder="Contraseña"
                      className="px-3 py-2 w-full outline-none placeholder:text-stone-600"
                      {...register("password", {
                        required: true,
                      })}
                    />
                  </div>
                  <p className="mt-4 flex text-sm text-stone-700">
                    No tienes una cuenta? &nbsp;
                    <Link
                      to={ROUTER_INITIAL}
                      target="_blank"
                      className="text-blue-500 font-semibold hover:underline"
                    >
                      Registrate
                    </Link>
                  </p>
                </div>

                <div className="mt-4 lg:mt-0 font-medium flex items-center justify-end">
                  <button type="button" onClick={close}>
                    Salir
                  </button>
                  <LoadingButton
                    isLoading={loading}
                    className="ml-4 px-4 py-1 rounded-md text-stone-100 bg-stone-950"
                  >
                    Continuar
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  );
}
