import { useEffect, useState } from "react";
import CardContainer from "../../components/CardContainer";

import { FaCircle } from "react-icons/fa";
import { GrCircleInformation } from "react-icons/gr";
import {
  Popover,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
} from "@radix-ui/react-popover";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";

import { ExtendLicenseModal } from "./Components/ExtendLicenseModal";
import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";
import { getLicense } from "./Api/AdminServices";
import { useSelector } from "react-redux";

const LicenceStatus = {
  ACTIVE: 1,
  EXPIRED: 2,
  BLOCKED: 3,
};

function licenceStyleByStatus(license) {
  if (!license) return;

  const status = license.status;
  switch (status) {
    case LicenceStatus.ACTIVE:
      return { color: "#62BD69", text: "Activa" };
    case LicenceStatus.EXPIRED:
      return { color: "#D30010", text: "Vencida" };
    case LicenceStatus.BLOCKED:
      return { color: "#808080", text: "Bloqueada" };
    default:
      return { color: "#A020F0", text: "Licencia desconocida" };
  }
}

function formatDate(date) {
  let dateString = date.slice(0, 10);
  let [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}
export function AdminLicense(props) {
  const [license, setLicense] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [modalId, setModalId] = useState("FDSCER");
  const token = useSelector((state) => state.login.token);


  const { user } = props;

  async function loadLicense() {

    const userId = user.id;
    getLicense(userId, token)
      .then((response) => {
        let licenseResponse = response.data;
        licenseResponse.fecha_fin = formatDate(licenseResponse.fecha_fin);
        licenseResponse.fecha_inicio = formatDate(licenseResponse.fecha_inicio);
        setLicense(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => {
      });
  }

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }

  useEffect(() => {
    loadLicense();
  }, []);

  const licenseStyle = licenceStyleByStatus(license);
  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Licencia
        </h2>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6">
        <CardContainer className="px-6 py-4 shadow-md">
          {isLoading ? (
            <LoadingSpinnerV2
              className="h-28 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <h2 className="font-medium"> Estado de licencia </h2>
              <div className="mt-3 overflow-x-auto sm:rounded-lg">
                <table className="w-full text-sm text-left text-stone-800">
                  <thead className="border-b border-b-stone">
                    <tr>
                      <th className="px-6 py-3 font-medium flex items-center">
                        Estado
                        <Popover>
                          <PopoverTrigger>
                            <GrCircleInformation
                              className="mt-[2px] ml-1 cursor-pointer"
                              size={14}
                            />
                          </PopoverTrigger>
                          <PopoverPortal>
                            <PopoverContent
                              sideOffset={5}
                              className="p-2 rounded border bg-white border-stone-300"
                            >
                              <div className="max-w-[200px] text-center flex-col text-xs">
                                <span>
                                  Si tu licencia venció o fue bloqueada tu
                                  perfil no será visible
                                </span>
                              </div>
                            </PopoverContent>
                          </PopoverPortal>
                        </Popover>
                      </th>
                      <th className="px-2 py-3 font-medium"> Inicio </th>
                      <th className="px-2 py-3 font-medium"> Vence el </th>
                      <th className="sr-only w-48"> Solicitar extensión </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-6 py-3">
                        <div className="flex items-center">
                          <FaCircle
                            size={10}
                            className="mr-2"
                            style={{ color: licenseStyle.color }}
                          />
                          {licenseStyle.text}
                        </div>
                      </td>
                      <td className="px-2 py-3"> {license.fecha_inicio} </td>
                      <td className="px-2 py-3"> {license.fecha_fin} </td>
                      <td className="py-2 w-48 hidden sm:table-cell">
                        <Dialog key={modalId} onOpenChange={reloadFormState}>
                          <DialogTrigger asChild>
                            <button
                              disabled={
                                license.status === LicenceStatus.BLOCKED
                              }
                              className="px-4 py-2 rounded-md font-semibold bg-emerald-500 disabled:bg-stone-300 disabled:text-stone-700 text-white"
                            >
                              Solicitar extensión
                            </button>
                          </DialogTrigger>
                          <ExtendLicenseModal license={license} />
                        </Dialog>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </CardContainer>

        {isLoading ? null : (
          <Dialog key={modalId} onOpenChange={reloadFormState}>
            <DialogTrigger asChild>
              <button
                disabled={license.status === LicenceStatus.BLOCKED}
                className="mt-4 px-4 py-2 sm:hidden w-full rounded-md font-semibold bg-emerald-500 disabled:bg-stone-300 disabled:text-stone-700 text-white"
              >
                Solicitar extensión
              </button>
            </DialogTrigger>
            <ExtendLicenseModal />
          </Dialog>
        )}
      </div>
    </div>
  );
}
