import React, { useState } from "react";

import { useSelector } from "react-redux";
import { DOMAIN } from "../../../../config/Constant";

import { useForm } from "react-hook-form";
import emailIcon from "../../../../assets/images/social_media_icons/icons8-mail-96.png";
import LoadingButton from "../../../../components/LoadingButton";
import { objectToLinkByType } from "../../../../utils/objectToLinkByType";
import { REGEX_EMAIL_VALUE } from "../../../../config/RegexConstants";

const AddEmailModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal, link } = props;
  const { onComplete, onSubmit } = props;

  const [isLoading, setIsLoading] = useState(false);

  const titleType = "de correo electrónico";
  const imageSource = emailIcon;

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      ...link,
    },
  });

  if (!onComplete) {
    console.log("Se debe pasar parámetro 'onComplete'");
  }
  if (!onSubmit) {
    console.log("Se debe pasar parámetro 'onSubmit'");
  }

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  function handleAddCustomSocialMedia(data) {
    setIsLoading(true);
    data = objectToLinkByType(data, "correo");
    onSubmit(data, token)
      .then((response) => {
        onComplete(response.data);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <p className="font-medium"> {`Agregar enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <div className="filter hover:contrast-[0.9]">
          <img
            className="max-w-[4rem] rounded-md cursor-pointer"
            alt={`Icono ${titleType}`}
            src={imageSource}
          />
        </div>
      </div>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <label className="text-stone-800 font-medium" htmlFor="email">
          Correo electrónico
        </label>
        <input
          formNoValidate
          className="px-4 py-2 border outline-none placeholder:text-stone-500"
          type="email"
          id="email"
          {...register("email", {
            pattern: {
              
              value: REGEX_EMAIL_VALUE,
            }
          })}
          placeholder="nombre@correo.com"
          required={true}
        />
        {formState.errors.email}
        <label className="mt-4 text-stone-800 font-medium" htmlFor="subject">
          Asunto
        </label>
        <input
          className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
          type="text"
          id="subject"
          {...register("subject")}
          placeholder="Asunto"
          required={true}
        />
        <label className="mt-4 text-stone-800 font-medium" htmlFor="body">
          Contenido
        </label>
        <textarea
          className="mt-1 px-4 py-2 border resize-none outline-none placeholder:text-stone-500"
          type="text"
          id="body"
          {...register("body")}
          placeholder="Contenido"
          maxLength={100}
          required={false}
        ></textarea>
        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              Agregar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddEmailModal;
