import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ShoppingCart } from "./Components/ShoppingCart";
import { TOKEN_RESULT, useVerifyToken } from "./Hooks/useVerifyToken";

import { useLocation } from "react-router-dom";
import { defaultProducts } from "./Products/defaultProducts";
import { ProductsCard } from "../landing_page/Components/ProductsCard";
import { FloatingShoppingCart } from "./Components/FloatingShoppingCart";
import { Navbar } from "../landing_page/Components/Navbar";

import LoadingButton from "../../components/LoadingButton";
import { Dialog } from "@radix-ui/react-dialog";
import { LoginModal } from "./Components/LoginModal";
import TokenRefresher from "../../components/TokenRefresher";
import { AiOutlineFileText } from "react-icons/ai";
import { OrderSummary } from "./Components/OrderSummary";
import { PaymentStageIndicator } from "./Components/PaymentStageIndicator";
import { PaymentUserInformation } from "./Components/PaymentUserInformation";
import { PaymentSCRUM } from "./Components/PaymentSCRUM";
import { REGEX_PHONE } from "../../config/RegexConstants";

import {getDiscountByVerificationCode} from "../admin_super/Api/discount.api"

import { toast } from "react-toastify";
export const PAYMENT_STEPS = {
  Cart: 0,
  Shipment: 1,
  Payment: 2,
  Completed: 3,
};

export function PaymentPage() {
  const token = useSelector((state) => state.login.token);
  const location = useLocation();

  const { isVerified, loading, user } = useVerifyToken(token);

  const [products, setProducts] = useState({});

  const [openLoginModal, setOpenLoginModal] = useState(false);

  const [stage, setStage] = useState(PAYMENT_STEPS.Cart);

  

  //Agregando datos para Discount
  const [verification_code, setVerificationCode]=useState('');
  const [isDiscountConfirmed, setIsDiscountConfirmed] = useState(false);
  const [discount_type, setDiscountType] = useState('');
  const [discount_rate, setDiscountRate] = useState('');
  var [discount_value, setDiscountValue] = useState(0);
  const [customer_user, setCustomerUser] = useState('');
  const [discount_id,setDiscountId]= useState('');

  const [paymentInfo, setPaymentInfo] = useState({
    city: "Santa Cruz",
    
  });

   function devolverDescuento() {


  console.log("valor decuento;", this.discountValue)
  return this.discountValue;
}



  if (isVerified !== TOKEN_RESULT.VALID && stage !== PAYMENT_STEPS.Cart) {
    // Esto parece recursivo, pero no lo es
    // Así si un token vence mientras está en la etapa de pago, lo envía al principio
    setStage(PAYMENT_STEPS.Cart);
  }

  function handlePayButton() {
    
    if (isVerified === TOKEN_RESULT.VALID) {
      if (stage === PAYMENT_STEPS.Cart) {
        if (Object.values(products).length === 0) {
          toast.error("Agrega items al carrito para comprar", {
            position: "top-center",
            autoClose: 1000,
            closeOnClick: true,
            theme: "colored",
          });
          return;
        }
        console.log("Monto de la transacción:", paymentInfo.monto);
        setStage(PAYMENT_STEPS.Shipment);
      }
      if (stage === PAYMENT_STEPS.Shipment) {
        if (paymentInfoIsValid()) {
          
          setStage(PAYMENT_STEPS.Payment);
        }
      }

      //para discount
      const verification_code = document.getElementById('discountInput').value;
      if (verification_code) {
        getDiscountByVerificationCode(token, verification_code)
          .then((discountInfo) => {
            console.log('Descuento encontrado:', discountInfo);
            if (discountInfo && discountInfo.data) {
              const { discount_type, discount_rate, customer_user, final_date, status } = discountInfo.data;

              // Verificar la fecha de finalización
              const currentDate = new Date();
              const discountEndDate = new Date(final_date);
              if (discountEndDate < currentDate) {
                toast.error('El cupón de descuento ha expirado', {
                  position: 'top-center',
                  autoClose: 1000,
                  closeOnClick: true,
                  theme: 'colored',
                });
                return;
              }
              // Verificar el estado del descuento
              if (!status) {
                toast.error('El cupón de descuento no está disponible actualmente', {
                  position: 'top-center',
                  autoClose: 1000,
                  closeOnClick: true,
                  theme: 'colored',
                });
                return;
              }

              setIsDiscountConfirmed(true);
              setVerificationCode(verification_code);
              setDiscountType((prevDiscountType) => discountInfo.data.discount_type || prevDiscountType);
              setDiscountRate((prevDiscountRate) => discountInfo.data.discount_rate || prevDiscountRate);
              setCustomerUser(customer_user);
              setDiscountId(discountInfo.data.id);
              
              console.log("discouunt type e:", discount_type)
              console.log("discouunt rate:", discount_rate)

              let  discountValue = calculateDiscountValue(
                discountInfo.discount_type,
                discountInfo.discount_rate
              );

             /*  console.log(parseFloat(discountValue));

              console.log("montodescuento: ", discount_value) */
              setDiscountValue(discountValue); 

              toast.success('¡Descuento encontrado!', {
                position: 'top-center',
                autoClose: 1000,
                closeOnClick: true,
                theme: 'colored',
              });
              setVerificationCode(verification_code);
            } else {
              
              toast.error('Código de descuento no válido', {
                position: 'top-center',
                autoClose: 1000,
                closeOnClick: true,
                theme: 'colored',
              });
            }


            



          })
          .catch((error) => {
            console.error('Error al obtener información del descuento:', error);
          });
      } 

      return;
    }else if (isVerified === TOKEN_RESULT.NEED_VERIFICATION) {
      handleUserNeedsVerification();
    } else {
      handleLogInOrRegister();
    }
  }







  function calculateDiscountValue(type, rate) {
    if (type === 'percentage') {

      var monto= parseFloat(rate) / 100;
  
        return monto; 
      
      /* // Si el descuento es porcentual, retorna la tasa en formato decimal
      return parseFloat(rate) / 100; */
    } else if (type === 'price') {
      // Si el descuento es un valor fijo, retorna ese valor
      return parseFloat(rate);
    }  else {
      // Otros casos según tus necesidades
      return null;
    } 
  }

 


  function paymentInfoIsValid() {
    return (
      paymentInfo.name &&
      paymentInfo.lastname &&
      paymentInfo.address &&
      paymentInfo.phone.match(REGEX_PHONE)

   


    );
  }

  function handleLogInOrRegister() {
    setOpenLoginModal(true);
  }

  function handleSuccessfulLoginModal() {
    setStage(PAYMENT_STEPS.Shipment);
  }

  function handleUserNeedsVerification() {
    window.alert(
      "Tu cuenta necesida ser verificada, revisa tu correo electrónico"
    );
  }

  function handleClickImageOrButtonProduct(product) {
    addProduct(product);
  }

  function addProduct(new_product) {
    if (stage !== PAYMENT_STEPS.Cart) {
      toast.error("Vuelve al carrito para agregar más productos", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const product = products[new_product.id];
    if (product) {
      product.quantity += 1;
    } else {
      new_product.quantity = 1;
    }

    setProducts({ ...products, [new_product.id]: new_product });
  }

  function handleGoBackStage() {
    setStage(stage - 1);
  }

  function renderPaymentStage() {
    switch (stage) {
      case PAYMENT_STEPS.Cart:
        return <ShoppingCart products={products} setProducts={setProducts} />;
      case PAYMENT_STEPS.Shipment:
        return (
          <PaymentUserInformation
            user={user}
            setPaymentInfo={setPaymentInfo}
            paymentInfo={paymentInfo}
            isDiscountConfirmed={isDiscountConfirmed}
            discount_type={discount_type}
            discount_rate={discount_rate}
            verification_code={verification_code}
            customer_user={customer_user}  
            calculateDiscountValue={calculateDiscountValue} 
            discount_id={discount_id}
          />
        );
      case PAYMENT_STEPS.Payment:
        return <PaymentSCRUM products={products} paymentInfo={paymentInfo} />;
      case PAYMENT_STEPS.Completed:
        return <></>;
      default:
        return <> Error raro </>;
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const product = defaultProducts[query.get("id")];

    if (product) {
      addProduct(product);
    }
    const body = document.getElementsByTagName("body")[0];
    body.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <TokenRefresher />
      <FloatingShoppingCart quantity={Object.keys(products).length} />
      <div className="pt-16 font-montserrat">
        <Navbar user={user} forceFixed={true} />
        <div className="pt-12 bg-white">
          <h1
            id="payment-title"
            className="px-4 sm:px-8 font-semibold text-xl sm:text-2xl"
          >
            Solicita tus productos de TuMascota
          </h1>

          <div className="px-4 sm:px-8 pt-4 pb-8">
            <PaymentStageIndicator
              products={products}
              setStage={setStage}
              stage={stage}
            />
            <div className="mt-2">{renderPaymentStage()}</div>
            <div className="mt-4 flex items-center">
              <AiOutlineFileText size={20} />
              <div className="ml-2 font-medium text-lg">Resumen de orden</div>
            </div>

            <div className="mt-4">
              <OrderSummary
                products={Object.values(products)}
                deliveryCost={paymentInfo.deliveryCost}
                discount_type={discount_type}
                discount_rate={discount_rate}
                calculateDiscountValue={calculateDiscountValue}
              />
            </div>

            <div className="w-full mt-4 flex justify-end">
            <input
              id="discountInput"
              type="text"
              placeholder="Ingresar Cupón"
              className="px-2 py-1 rounded-md border mr-2"
            />

              <button
                onClick={handleGoBackStage}
                disabled={PAYMENT_STEPS.Cart === stage}
                className="px-4 py-2 rounded-md border disabled:text-stone-500 text-stone-900 bg-stone-200"
              >
                Atras
              </button>
              <LoadingButton
                onClick={handlePayButton}
                isLoading={loading}
                className="ml-2 px-4 py-2 rounded-md text-stone-50 bg-stone-900"
              >
                Ir a pagar
              </LoadingButton>
              <Dialog open={openLoginModal}>
                <LoginModal
                  setOpenModal={setOpenLoginModal}
                  setStage={setStage}
                  onSuccessfulLogin={handleSuccessfulLoginModal}
                />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12 bg-stone-100">
        <h1 className="px-4 font-semibold text-2xl lg:text-3xl text-center">
          Elige tus productos
        </h1>

        <div className="mt-12 px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 items-center justify-items-center h-fit gap-y-20 gap-x-0">
          {Object.values(defaultProducts).map((product, key) => (
            <ProductsCard
              key={key}
              image={product.image}
              title={product.title}
              onClickImageOrButton={() =>
                handleClickImageOrButtonProduct(product)
              }
              hoverText="¡Agregar al carrito!"
              buttonText={`Pide una | Bs ${product.price}`}
              description={product.description}
              price={product.price}
            />
          ))}
        </div>


      </div>
    </>
  );
}