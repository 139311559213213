import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { HiCursorClick, HiDeviceMobile } from "react-icons/hi";
import { PieChart } from "react-minimal-pie-chart";

import { overrideUndefinedImage } from "../../utils/overrideUndefinedImage";
import {
  countClicks,
  sortStatsByCounter,
  highestFiveClicks,
} from "../../utils/analyticsUtils";
import {  IMAGE_DOMAIN } from "../../config/Constant";
import CardContainer from "../../components/CardContainer";
import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";
import { getUserStatistics } from "../admin/Api/UserServices";


function fixInconsistentImages(link) {
  let url = `${IMAGE_DOMAIN}${link.image}`;

  return overrideUndefinedImage("image", url);
}

const UserAnalytics = (props) => {
  const token = useSelector((state) => state.login.token);

  const [statistics, setStatistics] = useState([]);
  const [totalViews, setTotalViews] = useState(0);

  const [loadingActive, setLoadingActive] = useState(true);

  const { user } = props;

  useEffect(() => {
    getStatistic();

    const interval = setInterval(() => {
      getStatistic();
    }, 45000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getStatistic() {
    getUserStatistics(user.id, token).then((response) => {
      if (response.success === true) {
        setStatistics(flattenStats(response.data));
        setTotalViews(response.data.profile);
        setLoadingActive(false);
      }
    });
  }

  function flattenStats(stats) {
    const arrayMedia = [...(stats.custom_social_list ?? [])];

    return arrayMedia;
  }

  function getPieChartData() {
    const highestFive = highestFiveClicks(statistics);

    const colors = [
      "#2D87BB",
      "#F66D44",
      "#FEAE65",
      "#E6F69D",
      "#AADEA7",
      "#64C2A6",
    ];
    const data = highestFive.map((link, index) => ({
      title: link.title,
      value: link.counter,
      color: colors[index],
    }));
    return data;
  }

  return (
    <div className="mb-6 pt-8 w-full flex flex-col font-montserrat bg-[rgb(240,240,240)]">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Analíticas de Tu Mascota
        </h2>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1 lg:grid-cols-2">
        <CardContainer className="px-6 py-4 shadow-md">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <div className="p-2 w-fit rounded-lg bg-slate-100">
                <HiDeviceMobile size={20} className="text-stone-800" />
              </div>
              <h2 className="mt-1 font-medium text-stone-700">
                Cantidad de visitas
              </h2>
              <p className="mt-1 text-2xl font-bold text-stone-900">
                {totalViews}
              </p>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 shadow-md">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <div className="p-2 w-fit rounded-lg bg-slate-100">
                <HiCursorClick size={20} className="text-stone-800" />
              </div>
              <h2 className="mt-1 font-medium text-stone-700">
                Cantidad de clics
              </h2>
              <p className="mt-1 text-2xl font-bold text-stone-900">
                {countClicks(statistics)}
              </p>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 pb-6 shadow-md  col-span-1 lg:col-span-2 ">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <>
              <h2 className="font-medium">Enlaces con más interacciones</h2>

              <div className="flex flex-col sm:flex-row">
                <div className="h-32 mx-auto sm:mx-0 w-fit flex">
                  <PieChart
                    radius={40}
                    segmentsShift={(index) => (index === 0 ? 4 : 0)}
                    data={getPieChartData()}
                  />
                </div>

                <div className="mt-4 gap-x-6 gap-y-3 ml-4 grid grid-cols-2 sm:flex sm:flex-wrap overflow-x-auto sm:mr-auto">
                  {getPieChartData().map((data, index) => (
                    <div key={index}>
                      <div className="flex items-center" key={index}>
                        <div
                          className="h-[10px] rounded-full aspect-square"
                          style={{ backgroundColor: data.color }}
                        ></div>
                        <p className="ml-2 font-medium"> {data.title} </p>
                      </div>
                      <p className="ml-5 text-sm"> {data.value} clics </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </CardContainer>

        <CardContainer className="px-6 py-4 shadow-md col-span-full">
          {loadingActive ? (
            <LoadingSpinnerV2
              className="h-24 flex items-center w-fit mx-auto"
              size={24}
            />
          ) : (
            <table className="w-full border-spacing-y-16">
              <thead className="font-medium">
                <tr>
                  <th className="text-left"> Nombre </th>
                  <th> Interacciones </th>
                </tr>
              </thead>

              <tbody>
                {sortStatsByCounter(statistics).map((link, index) => (
                  <tr key={index}>
                    <td className="py-2 w-full flex items-center">
                      <img
                        className="ml-2 max-w-[2rem] aspect-square object-cover rounded-xl"
                        src={fixInconsistentImages(link)}
                        alt={`Icono de ${link.title}`}
                      />
                      <p className="ml-4">{link.title}</p>
                    </td>
                    <td className="text-center"> {link.counter} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </CardContainer>


        


        
      </div>
    </div>
  );
};
export default UserAnalytics;
