import { DOMAIN } from "../../../config/Constant";

export async function getAllCompanies(token) {
  const response = await fetch(DOMAIN + "profile/allEmpresas?page=1", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  return response.json();
}
function buildQueryOrNone(name, value) {
  if (value) {
    return `${name}=${value}`;
  }
  return "";
}
export async function updateUserAccountData(data, token) {
  let extra = buildQueryOrNone("user_id", data.user_id ?? data.id);
  const response = await fetch(`${DOMAIN}auth/user?${extra}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  const body = await response.json();
  if (!response.ok) {
    throw Error(body);
  }

  return body;
}

export async function createUser(data, token) {
  data.esEmpresa = data.tipo_usuario === "Empresa";
  data.correo = `${data.username}@tumascota.digital`;
  data.status = 1;

  const response = await fetch(DOMAIN + "auth/create-admin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });
  const body = await response.json();
  if (!response.ok) throw Error(body.error ?? "No se pudo crear el usuario");
  return body;
}

export async function createManyUsers(data, token) {
  const response = await fetch(DOMAIN + "auth/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) throw Error("Error al crear usuario");
  return response.json();
}
