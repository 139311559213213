import {
  objectToUserRole,
  userRoleToString,
} from "../../../utils/objectToUserRole";

export function UserRolTag(props) {
  const { user } = props;

  const colors = {
    0: "bg-sky-200 text-sky-700",
    1: "bg-red-200 text-red-700",
    2: "bg-emerald-200 text-emerald-700",
    3: "bg-gray-200 text-gray-700",
  };

  const classNameTag = "px-2 py-1 font-medium text-center w-fit rounded-xl";
  const userRole = objectToUserRole(user.custom_user);

  return (
    <div className={`${colors[userRole]} ${classNameTag}`}>
      <button>{userRoleToString(userRole)}</button>
    </div>
  );
}
